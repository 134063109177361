import { useSelector } from "react-redux";

// ********************************* AR EN LANGUAGE ***********************************

const GetLang = () => {
  return useSelector((state) => state.language);
};

const SetToEn = () => {
  return { type: "EN" };
};

const SetToAr = () => {
  return { type: "AR" };
};

// ********************************* Screen Size ***********************************
const GetScreenSize = () => {
  return useSelector((state) => state.screenSize);
};

const SetScreenSize = (size) => {
  return { type: "SetScreenSize", value: size };
};

// ********************************* Screen Size ***********************************
const GetMember = () => {
  return useSelector((state) => state.member);
};

const SetMember = (member) => {
  return { type: "SetMember", value: member };
};

export {
  GetLang,
  SetToEn,
  SetToAr,
  SetMember,
  GetScreenSize,
  SetScreenSize,
  GetMember,
};
