import React, { useEffect, useState } from "react";
import { useStyles } from "./ForymStyles";
import { GetLang } from "../../Actions/UserRedux";
import { Grid } from "@material-ui/core";
import ScrollToThis from "../../Components/Simple/ScrollToThis";
import Container1 from "../../Components/Simple/Container1";
import { useHistory } from "react-router";
import { memoryShow } from "../../services/ForMemServices";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Spinner from "../../Components/Simple/Spinner";
import MemoryCard from "../../Components/Cards/MemoryCard_v2/MemoryCard";

function ForumMemory3(props) {
  const cs = useStyles();
  let lang = GetLang() === "EN";

  const [memories] = useCollectionData(memoryShow, { idField: "id" });
  const [related_memories, set_related_memories] = useState(null);

  useEffect(() => {
    let tag = props.match.params.type;
    let newMemories = memories?.filter((memo) => memo.tags_ar === `${tag}#`);
    set_related_memories(newMemories);
  }, [memories, props.match.params.type]);

  return (
    <div className={cs.main}>
      <ScrollToThis />
      <Container1>
        <div className={lang ? "en" : " ar"}>
          <h1>{lang ? "Forum Memory" : "ذاكرة المنتدى"}</h1>

          <br />
          <br />
          <br />
          <br />

          <Grid
            container
            className="cards"
            spacing={4}
            direction={!lang ? "row-reverse" : "row"}
          >
            {related_memories ? (
              related_memories
                .filter((gh) => {
                  return (lang && gh.en) || (!lang && gh.ar);
                })
                .map((item, key) => {
                  return (
                    <Grid key={key} item lg={4} md={4} sm={6} xs={12}>
                      <div style={{ height: "100%" }}>
                        <MemoryCard memory={item} />
                      </div>
                    </Grid>
                  );
                })
            ) : (
              <Spinner />
            )}
          </Grid>
        </div>
      </Container1>
    </div>
  );
}

export default ForumMemory3;
