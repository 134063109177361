import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { NavLink } from "react-router-dom";

import { GetLang } from "../../Actions/UserRedux";
import Container1 from "../../Components/Simple/Container1";

import BlogCard from "../../Components/Cards/BlogCard/BlogCard";
import Spinner from "../../Components/Simple/Spinner";

import { ZoomIn } from "../../Components/Animations/Zoom";
import ScrollToThis from "../../Components/Simple/ScrollToThis";

import { getAllCardBlogs } from "../../services/BlogServices";

const useStyles = makeStyles((theme) => ({
  main: {
    // backgroundColor: "#0F0E0C",
    padding: "80px 0px",

    [theme.breakpoints.down("xs")]: {
      padding: "20px 0px",
    },

    textAlign: "center",
    "& h1": {
      display: "inline",
      color: "black",
      fontSize: "60px",
      borderBottom: "solid 5px black",
      padding: "10px 30px",
      margin: "0px",

      [theme.breakpoints.down("sm")]: {
        display: "block",
        fontSize: "35px",
        marginBottom: "0px",
      },
    },

    "& a": {
      textDecoration: "none",
    },

    // **************************** AR - EN ********************************

    "& .ar": {
      "& h1": {
        fontFamily: "Hudhud",
        fontWeight: "500",
      },
    },

    "& .en": {
      "& h1": {
        fontFamily: "roxbor",
      },
    },
  },
  items: {
    margin: "20px 0px",
  },
}));

function AllBlogs(props) {
  const cs = useStyles();
  let lang = GetLang() === "EN";
  const [blogs, setBlogs] = useState(null);

  useEffect(async () => {
    let currentCategs = props.match.params.type;
    const blgs = await getAllCardBlogs(currentCategs);
    setBlogs(blgs);
  }, [props.match.params.type]);

  return (
    <div className={cs.main}>
      <ScrollToThis />
      <Container1>
        <div className={lang ? "en" : " ar"}>
          <h1>{lang ? "All Of Our Blogs" : "جميع مدوناتنا"}</h1>
          <br />
          <br />
          <br />
          {blogs ? (
            <Grid
              container
              spacing={0}
              direction={!lang ? "row" : "row-reverse"}
            >
              {blogs
                .filter((blg) => {
                  return (lang && blg.en) || (!lang && blg.ar);
                })
                .map((card, key) => {
                  return (
                    <Grid
                      key={key}
                      item
                      lg={6}
                      md={6}
                      sm={12}
                      xs={12}
                      className={cs.items}
                    >
                      <BlogCard blog={card} />
                    </Grid>
                  );
                })}
            </Grid>
          ) : (
            <Spinner />
          )}
        </div>
      </Container1>
    </div>
  );
}

export default AllBlogs;
