import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
//import { autoPlay } from "react-swipeable-views-utils";
import Hidden from "@material-ui/core/Hidden";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

import { GetLang } from "../../Actions/UserRedux";
import BlackButton from "../../Components/Simple/BalckButton";

const AutoPlaySwipeableViews = SwipeableViews;

const useStyles = makeStyles((theme) => ({
  container: {
    position: "relative",
    padding: "30px",

    [theme.breakpoints.down("md")]: {
      padding: "10px",
    },

    "& .arrow": {
      position: "absolute",
      top: "50%",
      transform: "all",

      "& *": {
        fontSize: "30px",
      },
      "&:hover": {
        cursor: "pointer",
      },
    },

    "& .left": {
      left: "20px",
      "&:hover": {
        transform: "scale(1.1)",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "& .right": {
      right: "20px",
      transform: "translateY(-10px) rotate(180deg)",
      "&:hover": {
        transform: "translateY(-10px) scale(1.1) rotate(180deg)",
      },
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "& .show_btn": {
      width: "100%",
      display: "flex",
      justifyContent: "flex-end",
      paddingRight: "20px",

      [theme.breakpoints.down("md")]: {
        justifyContent: "center",
        alignItems: "center",
        marginTop: "20px",
        paddingRight: "0px",
      },
    },
  },

  S_stepper_puces: {
    width: "auto",
    margin: "40px auto 0px auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& div": {
      width: "20px",
      height: "8px",
      borderRadius: "200px",
      margin: "0px 4px",
      transition: "all 0.3s",
      cursor: "pointer",
      backgroundColor: "#A9A9A9",
    },

    "& .active": {
      backgroundColor: "#01161E",
      width: "60px",
      height: "7px",
    },
  },
}));

const Swipper2 = (props) => {
  const {
    elements,
    Component,
    color = "black",
    show_all = true,
    gotopage,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const { S_stepper_puces } = useStyles();
  const cs = useStyles();
  let lang = GetLang() === "EN";
  const theme = useTheme();

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const handleBack = () => {
    if (activeStep > 0) setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleNext = () => {
    if (activeStep < elements.length - 1)
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeStep < elements.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setActiveStep(0);
      }
    }, 999999); // 6000
    return () => {
      clearInterval(interval);
    };
  }, [elements, activeStep]);

  return (
    <div className={cs.container}>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={6000}
      >
        {elements.map((elem, key) => {
          return <Component key={key} elem={elem} />;
        })}
      </AutoPlaySwipeableViews>

      <div className="arrow left" style={{ color: `${color}` }}>
        <ArrowBackIosIcon onClick={handleBack} />
      </div>
      <div
        className={S_stepper_puces}
        style={{
          "& .active": {},
        }}
      >
        {elements.map((item, key) => {
          return (
            <div
              key={key}
              className={key === activeStep ? "active" : ""}
              onClick={() => handleStepChange(key)}
            />
          );
        })}
      </div>

      <Hidden smUp>
        {show_all ? (
          <div className="show_btn">
            <BlackButton onClick={gotopage} color={color}>
              {lang ? "Show All" : "إظهار الكل"}
            </BlackButton>
          </div>
        ) : (
          <></>
        )}
      </Hidden>

      <div className="arrow right" style={{ color: `${color}` }}>
        <ArrowBackIosIcon onClick={handleNext} />
      </div>
    </div>
  );
};

export default Swipper2;
