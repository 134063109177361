import React from "react";
import { useStyles } from "./blogcard.styles";
import { GetLang, GetScreenSize } from "../../../Actions/UserRedux";
import { useHistory } from "react-router";
import { getDateEn, getDateAr } from "../../../Functions/DateFunctions";

import aaa1 from "../../../Assets/svgs/svgexport-10.svg"; // outlined save
import aaa4 from "../../../Assets/svgs/svgexport-6.svg"; // outlined save
import aaa5 from "../../../Assets/svgs/svgexport-7.svg"; // messages
import { Avatar } from "@material-ui/core";

function BlogCard({ blog, isVert }) {
  const {
    title_en,
    title_ar,
    img,
    mob_img,
    id,
    date,
    author_img,
    author_en,
    author_ar,
    description_ar,
    categs,
  } = blog;
  let lang = GetLang() === "EN";
  let size = GetScreenSize();

  const history = useHistory();
  const openBlog = () => {
    history.push(`/blog/${id}`);
  };

  const css = useStyles();
  return (
    <div
      className={`${css.main} ${lang ? css.main_en : css.main_ar} ${
        isVert ? css.vertical : ""
      } `}
    >
      <img src={size === "xs" || isVert ? mob_img : img} alt={title_ar} />
      <div className="content">
        <h2>{title_ar}</h2>
        <div>
          <p className="description">{description_ar}</p>
          <p className="author">{author_ar}</p>
        </div>
        <div className="categ_and_more">
          {categs.map((categ, key) => {
            return (
              <div key={key} className="categ">
                {categ.title_ar}
              </div>
            );
          })}
          <span onClick={openBlog} className="more">
            عرض المزيد
          </span>
        </div>
      </div>

      {/* <div className="author">
        <h3 className="text">
          {lang ? `Author : ${author_en}` : `الكاتب(ة) : ${author_ar} `}
        </h3>
        <Avatar className="avatar" src={author_img} />
      </div>
      <div className="container_txt" onClick={openBlog}>
        <p>{lang ? title_en : title_ar}</p>
      </div>
      <div className="container_img_and_icon">
        <div className="container_img" onClick={openBlog}>
          <p className="date">{lang ? getDateEn(date) : getDateAr(date)}</p>
          <div className="img" style={{ backgroundImage: `url(${img})` }} />
        </div>
        <div className="container_icon">
          <img src={aaa1} />
          <img src={aaa5} />
          <img src={aaa4} />
        </div>
      </div> */}
    </div>
  );
}

export default BlogCard;
