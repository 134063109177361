import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close"; //
import Slide from "@material-ui/core/Slide"; //
import Typography from "@material-ui/core/Typography";

import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: "relative",
  },
  toolBar: {
    height: "50px",
    minHeight: "50px",
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
    textAlign: "center",
  },
  pdf: {
    width: "100%",
    height: "calc(100% - 50px)",
    position: "fixed",
    bottom: "0px",
    overflowY: "scroll",
    overflowX: "hidden",
    userSelect: "none",
    "-ms-overflow-style": "none",
    "scrollbar-width": "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    "& *": {
      userSelect: "none",
    },
    "& * canvas": {
      width: "100% !important",
      height: "auto  !important",
    },
    "& * .react-pdf__Page__textContent": {
      width: "100% !important",
    },
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ShowPdfType({ open, handleClose, title, Pdf }) {
  const classes = useStyles();

  const [numPages, setNumPages] = useState(null);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <AppBar className={classes.appBar}>
        <Toolbar className={classes.toolBar}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>

      <Document
        file={Pdf}
        onLoadError={console.error}
        onLoadSuccess={onDocumentLoadSuccess}
        className={classes.pdf}
      >
        {new Array(numPages).fill(0).map((val, index) => {
          return <Page key={index} pageNumber={index + 1} />;
        })}
      </Document>

      {/* <embed
        src={`${Pdf}#toolbar=0&navpanes=0&scrollbar=0`}
        width="100%"
        height="100%"
      /> */}
      {/*// <iframe
      //   src={`${Pdf}#toolbar=0&navpanes=0`}
      //   title={title}
      //   className={classes.pdf}
  // />*/}
      {/* <object data={Pdf} type="application/pdf">
        <iframe
          src={`https://docs.google.com/viewer?url=${{ Pdf }}&embedded=true`}
          frameborder="0"
          className={classes.pdf}
          webkitallowfullscreen
          mozallowfullscreen
          allowfullscreen
        ></iframe>
      </object> */}
    </Dialog>
  );
}
