import { firestore /*auth, firebase*/ } from "../config/firebase";

export const galleryStore = firestore.collection("gallery");
export const galleryShow = galleryStore
  .where("deleted", "==", false)
  .where("show", "==", true);
export const galleryHome = galleryShow.where("inhome", "==", true);

export const getOneGall = async (id) => {
  const response = galleryShow.where("__name__", "==", id);
  const data = await response.get();
  let oneGal = null;
  data.docs.forEach((item) => {
    oneGal = item.data();
  });
  return oneGal;
};

export const like_dislike_gallery = async (gallery_id, likes) => {
  await galleryStore.doc(gallery_id).update({ likes });
};
