import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";

import Hidden from "@material-ui/core/Hidden";

import Swipper2 from "../../../Components/Complex/Swipper2";

import Container1 from "../../../Components/Simple/Container1";
import BlackButton from "../../../Components/Simple/BalckButton";

import { ZoomIn } from "../../../Components/Animations/Zoom";
import { FadeLeft, FadeDown } from "../../../Components/Animations/Fade";
import { GetLang } from "../../../Actions/UserRedux";
import Spinner from "../../../Components/Simple/Spinner";
import { useHistory } from "react-router";
import BlogCard from "../../../Components/Cards/BlogCard/BlogCard";

import { useStyles } from "./styles";
import { NavLink } from "react-router-dom";

export default function OurBlogs({ blogs }) {
  const css = useStyles();
  let lang = GetLang() === "EN";
  return (
    <div className={css.S_container}>
      <h2 className={css.S_h2 + (!lang ? " ar_h2" : "")}>
        <FadeDown delay={0} duration={1000}>
          <NavLink to="/blogs">{lang ? <>Our Blogs</> : <>المدونة</>}</NavLink>
        </FadeDown>
      </h2>

      <div>
        {blogs.length ? (
          <Swipper2
            Component={Blog}
            show_all={false}
            elements={blogs.filter((blg) => {
              return (lang && blg.en) || (!lang && blg.ar);
            })}
          />
        ) : (
          <Spinner />
        )}
      </div>
    </div>
  );
}

const Blog = (props) => {
  const css = useStyles();
  const {
    title_en,
    title_ar,
    img,
    description_en,
    description_ar,
    id,
    author_ar,
    author_en,
    categs,
  } = props.elem;
  let lang = GetLang() === "EN";
  const history = useHistory();
  const openBlog = () => {
    history.push(`blog/${id}`);
  };

  return (
    <Container1>
      <div className={css.main}>
        <div className="horizantal_blog">
          <Grid
            style={{ overflow: "hidden" }}
            container
            spacing={0}
            direction={!lang ? "row-reverse" : "row"}
          >
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className={css.S_Right}
            >
              <ZoomIn delay={0} duration={1000}>
                <div
                  className={css.S_image}
                  style={{ backgroundImage: `url(${img})` }}
                />
              </ZoomIn>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={6}
              xl={6}
              className={css.S_Left}
            >
              <FadeLeft delay={0} duration={1000}>
                <div className={css.S_Text}>
                  <h2 className={css.S_h2 + ` ${!lang ? "ar_h2" : ""}`}>
                    {lang ? title_en : title_ar}
                  </h2>

                  <p className={!lang ? "ar_p" : ""}>
                    {lang ? description_en : description_ar}
                  </p>

                  <p className={`author ${!lang ? "ar_p" : ""}`}>
                    {lang ? author_en : author_ar}
                  </p>

                  <div className="categorys">
                    {categs.map((categ, key) => {
                      return (
                        <div key={key} className="categ">
                          {" "}
                          {categ.title_ar}{" "}
                        </div>
                      );
                    })}
                  </div>

                  <div className="moreBtn">
                    <BlackButton onClick={openBlog}>
                      {lang ? <>VIEW MORE</> : <>عرض المزيد</>}
                    </BlackButton>
                  </div>
                </div>
              </FadeLeft>
            </Grid>
          </Grid>
        </div>
        <div className="card_blog">
          <BlogCard blog={props.elem} />
        </div>
      </div>
    </Container1>
  );
};
