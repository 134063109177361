import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { GetLang } from "../../Actions/UserRedux";

const useStyles = makeStyles((theme) => ({
  S_sign_btn: {
    backgroundColor: "#FA6559",
    // color: theme.palette.black.text,
    color: "white",
    fontSize: "22px",
    fontFamily: "Abhaya",
    textTransform: "none",
    borderRadius: "0px",

    //width: "145px",
    padding: "0px 20px",
    height: "45px",
    borderRadius: "10px",
    transition: "all 0.3s",
    "&:hover": {
      backgroundColor: "#FA6559",
      transform: "scale(1.1)",
    },
  },
  ar: {
    fontFamily: "AraHamah",
  },
  white: {
    backgroundColor: theme.palette.black.text,
    color: theme.palette.black.main,
    "&:hover": {
      backgroundColor: theme.palette.black.text,
      transform: "scale(1.1)",
    },
  },
}));

//Sign up
const RedButton = (props) => {
  const { S_sign_btn, ar, white } = useStyles();
  const { style = {}, color = "black", onClick } = props;
  let lang = GetLang() === "EN";

  return (
    <Button
      variant="contained"
      onClick={onClick}
      style={{ ...style }}
      className={
        S_sign_btn + ` ${!lang && ar}` + ` ${color === "white" && white}`
      }
    >
      {props.children}
    </Button>
  );
};

export default RedButton;
