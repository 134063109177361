import { makeStyles } from "@material-ui/styles";
import bg from "../../../Assets/Images/blog_ctg_bg.png";
import mb_bg from "../../../Assets/Images/blog_mb_bg.png";

export const useStyles = makeStyles((theme) => ({
  main: {
    // backgroundColor: "#0F0E0C",
    padding: "0px 0px 100px 0px",
  },

  article: {
    backgroundColor: "white",
    borderRadius: "10px",
    color: "#111",
    margin: "20px",
    padding: "20px 40px",
    [theme.breakpoints.down("xs")]: {
      margin: "10px 0px",
      padding: "20px 18px",
    },
  },

  head: {
    "& .date_and_category": {
      width: "100%",
      borderBottom: "solid 2px black",
      padding: "0px 10px",
      marginBottom: "50px",

      fontFamily: "roxbor",

      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",

      [theme.breakpoints.down("xs")]: {
        marginBottom: "0px",
        flexDirection: "column-reverse !important",
      },

      "& h3": {
        margin: "10px 0px",
        padding: "0px",
      },
      "& h3.author_part": {
        display: "flex",
        alignItems: "center",
        "& .avatar": {
          margin: "0px 7px 0px 0px",
        },
      },
      "& h3.date_part": {
        [theme.breakpoints.down("xs")]: {
          display: "none",
        },
      },
    },
  },

  body: {
    "& h1,h2,h2,h4": {
      fontFamily: "roxbor",
      display: "block",
      clear: "both",
      direction: "ltr",
    },

    "& h1": {
      marginBottom: "30px",
      fontSize: "40px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },

    "& h2": {
      fontSize: "30px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
      },
    },

    "& p": {
      fontFamily: "Aileron",
      fontSize: "20px",
      fontWeight: "900",
      lineHeight: "33px",
      textAlign: "justify",
      [theme.breakpoints.down("xs")]: {
        fontSize: "18px",
      },
    },

    "& img": {
      display: "block",
      width: "100%",
      borderRadius: "10px",
    },

    "& .img": {
      display: "inline",
      height: "300px",
      width: "400px",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      marginBottom: "1em",

      [theme.breakpoints.down("md")]: {
        display: "block",
        width: "100%",
      },
    },

    "& .left": {
      float: "left",
      marginRight: "2em",
    },

    "& .right": {
      float: "right",
      marginLeft: "2em",
    },
  },

  footer: {
    width: "100%",
    borderTop: "solid 2px black",
    marginTop: "50px",
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    flexWrap: "wrap",
    "& h3 , p": {
      margin: "10px",
      padding: "0px",
      fontSize: "20px",
      fontWeight: "700",
      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        margin: "5px",
        fontWeight: "600",
      },
    },
    "& p": {
      backgroundColor: "#B74440",
      padding: "0px 15px 3px 15px",
      color: "white",
      fontSize: "16px",
      fontWeight: "600",
      lineHeight: "1.75 !important",
      borderRadius: "6px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "14px",
        borderRadius: "5px",
        padding: "2px 10px 4px 10px",
        lineHeight: "1.4 !important",
      },
    },
  },

  article_ar: {
    "& .date_and_category": {
      flexDirection: "row-reverse",
      fontFamily: "Jazera",
      fontWeight: "800",
      "& h3.author_part": {
        "& .avatar": {
          margin: "0px 0px 0px 7px",
        },
      },
    },
    "& h1,h2,h3,h4": {
      direction: "rtl",
      fontFamily: "Jazera",
      fontWeight: "800",
      textAlign: "right",
    },
    "& h1": {},
    "& h2": {},
    "& p": {
      fontFamily: "Jazera",
      lineHeight: "2",
      textAlign: "justify",
      direction: "rtl",
      fontWeight: "500",
    },
    "& .footer": {
      justifyContent: "right",
      flexDirection: "row-reverse",
    },
  },

  cards_for_selections: {
    width: "100%",
    padding: "100px 50px",
    backgroundImage: `url(${bg})`,
    backgroundPosition: "50% 50%",
    backgroundSize: "cover",
    backgroundRepeat: "no-repear",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "70px",

    [theme.breakpoints.down("xs")]: {
      height: "auto",
      marginTop: "0px",
      padding: "100px 20px",
      backgroundImage: `url(${mb_bg})`,
    },

    "& .group_2": {
      width: "23%",
      height: "100%",

      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",

      [theme.breakpoints.down("xs")]: {
        width: "48%",
      },

      "& .card": {
        backgroundColor: "white",
        width: "100%",
        border: "solid 1px #a8b3cf33",
        borderRadius: "20px",
        padding: "20px",
        fontSize: "25px",
        color: "black",
        position: "relative",
        margin: "20px 0px",
        textAlign: "center",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",

        transition: "all 0.2s",
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.05)",
        },

        "& .icon": {
          position: "absolute",
          top: "15px",
          right: "15px",
          color: "black",
          fontSize: "30px",
        },
      },

      "& .card_ar": {
        fontFamily: "Hudhud",
      },

      "& .h25": {
        height: "40%",
        height: "100px",
      },
      "& .h50": {
        height: "40%",
        height: "100px",
      },
      "& .h75": {
        height: "40%",
        height: "100px",
      },
      "& .h75, .h50, .h25": {
        [theme.breakpoints.down("xs")]: {
          height: "140px",
          margin: "20px 0px",
          "&:hover": {
            transform: "scale(1) !important",
          },
        },
      },
    },
  },
}));
