import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  S_wpf: {
    padding: "50px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "20px 0px",
    },
  },

  S_image: {
    backgroundSize: "cover",
    backgroundPosition: "20% center",
    height: "500px",
    width: "400px",
    margin: "auto",
    position: "relative",

    [theme.breakpoints.down("md")]: {
      width: "80%",
    },

    [theme.breakpoints.down("sm")]: {
      height: "400px",
    },

    [theme.breakpoints.down("xs")]: {
      width: "75%",
      height: "350px",
      margin: "0px 0px -20px -5%",

      "&:before": {
        content: '""',
        position: "absolute",
        right: "-7px",
        bottom: "0px",
        background: "white",
        width: "1.8px",
        height: "70%",
      },
    },
  },
  S_Left: {
    borderRight: "1px solid black",
    [theme.breakpoints.down("md")]: {
      border: "none",
    },
  },
  S_Right: {
    borderLeft: "2px solid black",
    [theme.breakpoints.down("md")]: {
      border: "none",
    },
  },
  S_Text: {
    padding: "0px 90px",
    color: "black",
    fontFamily: "roxbor",
    textAlign: "center",

    [theme.breakpoints.down("md")]: {
      padding: "23px",
    },

    "& p": {
      fontWeight: "300",
      fontFamily: "Aileron",
      fontSize: "22px",
      margin: "20px 0px",
      textAlign: "justify",
    },

    "& .ar_h2": {
      fontFamily: "Hudhud",
      fontSize: "50px",
    },
    "& .ar_p": {
      fontFamily: "Jazera",
      fontSize: "25px",
      direction: "rtl",
    },
    "& .en_p": {
      lineHeight: "1.5",
    },
  },
  S_h2: {
    fontWeight: "300",
    fontSize: "40px",
    marginTop: "30px",
    marginBottom: "40px",

    "& *": {
      textDecoration: "none",
      color: "black",
    },
  },
  moreBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& button": {
      backgroundColor: "black",
      color: "white",
      "&:hover": {
        backgroundColor: "black !important",
        color: "white",
      },
    },
  },
}));
