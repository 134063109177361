import React from "react";
import { useStyles } from "./styles";
import { GetLang } from "../../../Actions/UserRedux";

import EventNoteIcon from "@material-ui/icons/EventNote";
import RoomIcon from "@material-ui/icons/Room";

import { FullDateAr, FullDateEn } from "../../../Functions/DateFunctions";

const EventCard = (props) => {
  const { elem } = props;
  let lang = GetLang() === "EN";

  const openFrom = () => {
    window.open(elem.google_form, "_blank");
  };

  const styles = useStyles();
  return (
    <div className={styles.main}>
      <div className="event">
        <div className="img_container">
          <img src={elem.imgH} alt={elem.title_ar} />
        </div>
        <div className="data_container">
          <h2>{elem.title_ar}</h2>
          <p>{elem.description_ar}</p>
          <div className="date">
            <EventNoteIcon className="icon" />
            <span className="value"> {FullDateAr(elem.date, elem.time)} </span>
          </div>

          <div className="location">
            <RoomIcon className="icon" />
            <span className="value"> {elem.location_ar} </span>
          </div>
          <button onClick={openFrom}>الإشتراك</button>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
