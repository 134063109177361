import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    padding: "40px 0px",
    position: "relative",
  },

  S_h2: {
    fontSize: "40px",
    marginTop: "40px",
    marginBottom: "20px",

    "& *": {
      textDecoration: "none",
      color: "black",
    },

    [theme.breakpoints.down("xs")]: {
      marginTop: "10px",
    },
  },

  ar_h2: {
    fontSize: "60px",
    fontFamily: "Hudhud",
    margin: "20px 0px 0px 0px",
    [theme.breakpoints.down("md")]: {
      textAlign: "right",
    },
  },

  S_h2_fixer: {
    textAlign: "right",
    padding: "0px 60px",
    fontWeight: "200",

    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px 20px 0px",
      textAlign: "center",
    },
  },

  event: {},
}));
