import { createStore, combineReducers } from "redux";

const saveToLocal = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (e) {
    //console.log(e);
  }
};

function loadFromLocal() {
  try {
    const serializedState = localStorage.getItem("state");
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    //console.log(e);
    return undefined;
  }
}

/* *********************************** Reducers ********************************* */
/* ****************************************************************************** */
/* ****************************************************************************** */
/* ****************************************************************************** */

const Language = (state = "AR", action) => {
  switch (action.type) {
    case "EN":
      return "EN";
    case "AR":
      return "AR";
    default:
      return state;
  }
};

const ScreenSize = (state = "lg", action) => {
  switch (action.type) {
    case "SetScreenSize":
      return action.value;
    default:
      return state;
  }
};

const Members = (state = null, action) => {
  switch (action.type) {
    case "SetMember":
      return action.value;
    default:
      return state;
  }
};

/* ****************************************************************************** */
/* ****************************************************************************** */
/* ****************************************************************************** */
/* *********************************** Reducers ********************************* */

const allReducers = combineReducers({
  language: Language,
  screenSize: ScreenSize,
  member: Members,
});

const load = loadFromLocal();
const store = createStore(
  allReducers,
  load,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

store.subscribe(() => saveToLocal(store.getState()));

export { store };
