import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";

import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";

import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import RedButton from "../../Components/Simple/RedButton";

import {
  GetLang,
  SetToAr,
  SetToEn,
  GetScreenSize,
  GetMember,
  SetMember,
} from "../../Actions/UserRedux";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { SignInFn, logout } from "../../services/Auth";
import MemosNav from "../SubNavBars/MemosNav/MemosNav";
import BlogsNav from "../SubNavBars/BlogsNav/BlogsNav";
import Edjes from "../SubNavBars/Edjes/Edjes";

import menu_icon from "../../Assets/svgs/menu.svg";
import menu_icon2 from "../../Assets/svgs/menu2.svg";

const NavBar = () => {
  const { S_nav, S_routes, shrink, open_navbar, en_navbar, ar_navbar } =
    useStyles();
  const cs = useStyles();
  const [open, setOpen] = useState(false);
  const [navShrink, setNavShrink] = useState(false);
  const [subMemNav, setSubMemNav] = useState(false);
  const [subBlogNav, setSubBlogNav] = useState(false);

  const dispatch = useDispatch();
  let lang = GetLang();
  let size = GetScreenSize();

  useEffect(() => {
    const onScroll = (e) => {
      if (e.target.documentElement.scrollTop > 80) {
        setNavShrink(true);
      } else {
        setNavShrink(false);
      }
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const ChoseEn = () => {
    dispatch(SetToEn());
  };

  const ChoseAr = () => {
    dispatch(SetToAr());
  };

  useEffect(() => {
    ChoseAr();
  }, []);

  const MouseEnter = (type) => {
    if (type === "memory") {
      setSubMemNav(true);
    } else if (type === "blog") {
      setSubBlogNav(true);
    }
  };

  const MouseLeave = (type) => {
    if (type === "memory") {
      setSubMemNav(false);
    } else if (type === "blog") {
      setSubBlogNav(false);
    }
  };

  return (
    <div>
      <nav
        className={
          S_nav +
          " " +
          (navShrink && shrink) +
          " " +
          (lang === "EN" && (size === "sm" || size === "xs")
            ? cs.flexStart
            : "") +
          " " +
          (open ? open_navbar : "") +
          " " +
          (lang === "EN" ? en_navbar : ar_navbar)
        }
      >
        <Hidden smDown>
          <div className={S_routes}>
            {/* <div className="lang" onClick={ChoseEn}>
              EN
            </div>
            <div className="lang push" onClick={ChoseAr}>
              AR
            </div> */}

            {lang === "EN" ? (
              <>
                <div>
                  <NavLink to="/home">HOME</NavLink>
                </div>
                <div>
                  <NavLink to="/gallery">GALLERY</NavLink>
                </div>
                <div
                  onMouseOver={() => {
                    MouseEnter("memory");
                  }}
                >
                  <Edjes
                    onMouseLeave={() => {
                      MouseLeave("memory");
                    }}
                  />
                  <NavLink to="/forum_memory">FORUM MEMORY</NavLink>
                </div>
                <div>
                  <NavLink to="/events">EVENTS</NavLink>
                </div>
                <div
                  onMouseOver={() => {
                    MouseEnter("blog");
                  }}
                >
                  <Edjes
                    onMouseLeave={() => {
                      MouseLeave("blog");
                    }}
                  />
                  <NavLink to="/blogs">BLOGS</NavLink>
                </div>
                <div>
                  <NavLink to="/about">ABOUT</NavLink>
                </div>
              </>
            ) : (
              <>
                <div className="ar">
                  <NavLink to="/about">عن المنتدى </NavLink>
                </div>
                <div className="ar">
                  <NavLink to="/events">الفعاليات</NavLink>
                </div>
                <div
                  className="ar"
                  onMouseOver={() => {
                    MouseEnter("blog");
                  }}
                >
                  <Edjes
                    onMouseLeave={() => {
                      MouseLeave("blog");
                    }}
                  />
                  <NavLink to="/blogs">المدونة</NavLink>
                </div>
                <div
                  className="ar"
                  onMouseOver={() => {
                    MouseEnter("memory");
                  }}
                >
                  <Edjes
                    onMouseLeave={() => {
                      MouseLeave("memory");
                    }}
                  />
                  <NavLink to="/forum_memory">ذاكرة المنتدى</NavLink>
                </div>
                <div className="ar">
                  <NavLink to="/gallery">المعرض</NavLink>
                </div>
                <div className="ar">
                  <NavLink to="/home">الرئيسية</NavLink>
                </div>
              </>
            )}
          </div>
          <LogInLogOut lang={lang} />
        </Hidden>

        <Hidden mdUp>
          <IconButton
            size="large"
            className={`
            ${cs.menu_icon} 
            iconbtnMenu 
            ${lang === "EN" ? cs.menu_icon_L : cs.menu_icon_R}
            `}
            onClick={() => {
              setOpen(!open);
            }}
          >
            {open ? (
              <CloseIcon className="close_icon" fontSize="large" />
            ) : (
              <img src={navShrink ? menu_icon : menu_icon2} alt="icon" />
              // <MenuIcon fontSize="large" />
            )}
          </IconButton>
          <div
            className={
              cs.s_routes_resp +
              " " +
              (open ? cs.s_routes_resp_open : "") +
              " " +
              (lang === "EN" ? "" : cs.routes_resp_R)
            }
          >
            <div className="container">
              {lang === "EN" ? (
                <>
                  <h3>
                    <NavLink to="/home">HOME</NavLink>
                  </h3>
                  <h3>
                    <NavLink to="/gallery">GALLERY</NavLink>
                  </h3>
                  <h3>
                    <NavLink to="/forum_memory">FORUM MEMORY</NavLink>
                  </h3>
                  <h3>
                    <NavLink to="/events">EVENTS</NavLink>
                  </h3>
                  <h3>
                    <NavLink to="/blogs">BLOGS</NavLink>
                  </h3>
                  <h3>
                    <NavLink to="/about">ABOUT</NavLink>
                  </h3>

                  {/* <h3>
                    <span className="lang" onClick={ChoseEn}>
                      EN
                    </span>
                    <span className="space" />
                    -
                    <span className="space" />
                    <span className="lang" onClick={ChoseAr}>
                      AR
                    </span>
                  </h3> */}
                </>
              ) : (
                <>
                  <h3 className="ar">
                    <NavLink to="/about">عن المنتدى</NavLink>
                  </h3>
                  <h3 className="ar">
                    <NavLink to="/events">الفعاليات</NavLink>
                  </h3>
                  <h3 className="ar">
                    <NavLink to="/blogs">المدونة</NavLink>
                  </h3>
                  <h3 className="ar">
                    <NavLink to="/forum_memory">ذاكرة المنتدى</NavLink>
                  </h3>
                  <h3 className="ar">
                    <NavLink to="/gallery">المعرض</NavLink>
                  </h3>
                  <h3 className="ar">
                    <NavLink to="/home">الرئيسية</NavLink>
                  </h3>

                  {/* <h3 className="ar">
                    <span className="lang" onClick={ChoseEn}>
                      EN
                    </span>
                    <span className="space" />
                    -
                    <span className="space" />
                    <span className="lang" onClick={ChoseAr}>
                      AR
                    </span>
                  </h3> */}
                </>
              )}
            </div>
            <div className="button">
              <LogInLogOut lang={lang} />
            </div>
          </div>
        </Hidden>
      </nav>
      {subMemNav && (
        <MemosNav
          navShrink={navShrink}
          close={() => {
            MouseLeave("memory");
          }}
        />
      )}
      {subBlogNav && (
        <BlogsNav
          navShrink={navShrink}
          close={() => {
            MouseLeave("blog");
          }}
        />
      )}
    </div>
  );
};

export default NavBar;

const LogInLogOut = ({ lang }) => {
  const dispatch = useDispatch();
  let member = GetMember();

  const Login = async () => {
    dispatch(SetMember(await SignInFn()));
  };

  const Logout = async () => {
    dispatch(SetMember(await logout()));
  };

  return (
    <>
      {member ? (
        <RedButton onClick={Logout}>
          {lang === "EN" ? "Sign out" : "تسجيل الخروج"}
        </RedButton>
      ) : (
        <RedButton onClick={Login}>
          {lang === "EN" ? "Sign up" : "الإشتراك"}
        </RedButton>
      )}
    </>
  );
};
