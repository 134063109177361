import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import { GetLang } from "../../../Actions/UserRedux";
import { useHistory } from "react-router";
import { NavLink } from "react-router-dom";

import Liked from "../../../Assets/svgs/filled_heart.svg";
import NotLiked from "../../../Assets/svgs/empty_heart.svg";

import { useStyles } from "./styles";

import ShowPdfType from "../showGallery/ShowPdfType";
import { useSelector } from "react-redux";
import { like_dislike_gallery } from "../../../services/GalleryServices";

import { toast } from "react-hot-toast";

const useStyles2 = makeStyles((theme) => ({
  main: {
    cursor: "pointer",

    "& .gal": {
      padding: "0px 50px",
      marginBottom: "80px",
      textAlign: "left",
    },

    "& .title_ar": {
      fontFamily: "Hudhud",
    },

    "& .img": {
      backgroundPosition: "center center",
      backgroundSize: "cover",
      height: "440px",
      width: "100%",
      position: "relative",
      // borderRadius: "10px",
      border: "solid 1px rgba(0,0,0,0.12)",

      "&:before": {
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        opacity: 0.1,
        background: "rgba(255,255,255)",
      },
    },

    "& .icon": {
      position: "absolute",
      top: "30px",
      right: "80px",
      "& *": {
        fontSize: "43px",
      },
    },

    "& .title": {
      position: "relative",
      zIndex: "1",
      fontSize: "30px",
      fontWeight: "200",
      margin: "-80px 0px 0px -50px",
      color: "black",
    },
  },

  real_gal: {
    "& .big": {
      display: "block",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "& .small": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  },
}));

const GalleryItem = (props) => {
  const cs = useStyles2();
  const { title_en, img, title_ar, id, type = "normal", Pdf = "" } = props.gal;
  const history = useHistory();

  const [openPdf, setOpenPdf] = useState(false);

  const handleClosePdf = () => {
    setOpenPdf(false);
    console.log("closing");
  };

  const goToOneGall = () => {
    if (type === "Images") {
      history.push(`gallery/${id}`);
    } else if (type === "Pdf") {
      setOpenPdf(true);
    }
  };

  let lang = GetLang() === "EN";
  return (
    <div className={cs.main}>
      <div onClick={goToOneGall} className="gal">
        <div className="img" style={{ backgroundImage: `url(${img})` }} />
        {/* <div className="icon">
            {type === "Pdf" && <CollectionsBookmarkIcon />}
            {type === "Images" && <LibraryBooksIcon />}
          </div> */}
        <h3 className={"title " + (!lang ? " title_ar" : "")}>
          {lang ? title_en : title_ar}
        </h3>
        <div className="isSm"></div>
      </div>
      <ShowPdfType
        Pdf={Pdf}
        title={lang ? title_en : title_ar}
        open={openPdf}
        handleClose={handleClosePdf}
      />
    </div>
  );
};

const SmallGalleryCard = (props) => {
  const {
    title_en,
    img,
    title_ar,
    id,
    type = "normal",
    Pdf = "",
    likes = [],
    google_form,
  } = props.gal;
  let lang = GetLang() === "EN";
  const css = useStyles();
  const history = useHistory();

  const [openPdf, setOpenPdf] = useState(false);
  const [liked, setLiked] = useState(false);

  const user = useSelector((state) => state.member);

  useEffect(() => {
    setLiked(likes?.indexOf(user?.id) !== -1);
  }, [likes, user]);

  const like_dislike = () => {
    if (user) {
      let new_likes = likes;
      if (likes.indexOf(user?.id) === -1) {
        new_likes = [...new_likes, user.id];
      } else {
        new_likes = likes.filter((lk) => lk !== user?.id);
      }
      like_dislike_gallery(id, new_likes);
    } else {
      toast.error("قم بالأشتراك أولا");
    }
  };

  const handleClosePdf = () => {
    setOpenPdf(false);
  };

  const goToOneGall = () => {
    if (type === "Images") {
      history.push(`gallery/${id}`);
    } else if (type === "Pdf") {
      setOpenPdf(true);
    }
  };

  return (
    <div>
      <div className={`${css.main} ${lang ? css.main_en : css.main_ar} `}>
        <div className="container_img">
          <img className="img" src={img} alt={title_en} />
        </div>

        <div className="title">
          <p>{lang ? title_en : title_ar}</p>
        </div>

        <div className="details">
          <div className="tag_and_date">
            <span className="date"></span>
            <span className="tag">
              <a target="_blank" href={google_form}>
                أشتري الكتاب
              </a>
            </span>
          </div>

          <div className="categ_and_likes">
            <span className="likes">
              <div
                onClick={like_dislike}
                className={false ? "like liked" : "like"}
              >
                {liked ? (
                  <img src={Liked} alt="heart" className="icon" />
                ) : (
                  <img src={NotLiked} alt="heart" className="icon" />
                )}
              </div>
              <div className="value"> {likes?.length || 0} </div>
            </span>
            <span className="categ" onClick={goToOneGall}>
              المزيد من الكتاب
            </span>
          </div>
        </div>
      </div>
      <ShowPdfType
        Pdf={Pdf}
        title={lang ? title_en : title_ar}
        open={openPdf}
        handleClose={handleClosePdf}
      />
    </div>
  );
};

const RealGallery = (props) => {
  const styles = useStyles2();

  return (
    <div className={styles.real_gal}>
      <div className="big">
        <GalleryItem {...props} />
      </div>
      <div className="small">
        <SmallGalleryCard {...props} />
      </div>
    </div>
  );
};

export default SmallGalleryCard;
