import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  main: {
    width: "270px",
    height: "100%",
    margin: "50px auto",
    display: "flex",
    flexDirection: "column",

    [theme.breakpoints.down("xs")]: {
      margin: "20px auto 30px auto",
    },

    "& .img": {
      height: "240px",
      width: "240px",
      margin: "0px auto 30px auto",
      borderRadius: "50px 50px  50px 10px",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      boxShadow: "2px 2px 10px 1px #13613A",
      transition: "all 0.3s",

      [theme.breakpoints.down("xs")]: {
        height: "200px",
        width: "200px",
        boxShadow: "2px 2px 5px 1px #13613A",
        borderRadius: "100px 100px  100px 50px",
      },
    },

    "&:hover": {
      "& .img": {
        transform: "scale(1.05)",
      },
    },
    "& h1,h2": {
      margin: "0px",
      padding: "0px",
      //textAlign: "left !important",
      textAlign: "center !important",
    },
    "& h1": {
      fontSize: "30px !important",
      fontWeight: "700",
    },
    "& h2": {
      fontSize: "25px !important",
      fontWeight: "500",
    },
    "& .h1_ar": {
      fontSize: "32px !important",
      fontFamily: "Hudhud",
      fontWeight: "500",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px !important",
      },
    },
    "& .h2_ar": {
      fontSize: "27px !important",
      fontFamily: "HSDreamLight",
      lineHeight: "1.8",
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px !important",
      },
    },
  },
}));

function PersonCard(props) {
  const cs = useStyles();
  const { img, name_en, position, name_ar, position_ar } = props.person;
  const { lang } = props;
  const { isVisible } = props;
  return (
    <div className={cs.main}>
      <div className="img" style={{ backgroundImage: `url(${img})` }} />
      <h1 className={lang ? "h1_en" : "h1_ar"}>
        {lang ? name_en : name_ar}
        <hr className={isVisible ? "extend" : "no"} />
      </h1>
      <h2 className={lang ? "h2_en" : "h2_ar"}>
        {/* {lang ? position : position_ar} */}
      </h2>
    </div>
  );
}

export default PersonCard;
