import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  contact_item: {
    height: "100%",

    [theme.breakpoints.down("xs")]: {
      margin: "20px 0px",
    },

    "& h4": {
      fontWeight: "500",
      letterSpacing: "1px",
      fontSize: "20px",
      lineHeight: "21px",
      padding: "5px 0px",
      margin: "0px",

      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        lineHeight: "16px",
      },
    },

    "& p": {
      fontWeight: "100",
      fontSize: "20px",
      lineHeight: "18px",
      fontFamily: "AraHamah",

      [theme.breakpoints.down("xs")]: {
        fontSize: "16px",
        lineHeight: "16px",
      },
    },

    "& a": {
      textDecoration: "none",
      color: "white",
    },
  },
}));

function ContactItem(props) {
  const { title, items, isLink = false, same = false } = props.contact;
  const cs = useStyles();

  return (
    <div className={cs.contact_item}>
      <h4>{title}</h4>
      <div className="content">
        {items.map((item, key) => {
          if (isLink) {
            return (
              <p
                styles={{ direction: item.type === "phone" ? "rtl" : "ltr" }}
                key={key}
              >
                <a href={item.link} target={same ? "" : "_blank"}>
                  {item.text}
                </a>
              </p>
            );
          } else {
            return <p key={key}>{item.text}</p>;
          }
        })}
      </div>
    </div>
  );
}

export default ContactItem;
