import React, { useState, useEffect } from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SwipeableViews from "react-swipeable-views";
//import { autoPlay } from "react-swipeable-views-utils";

const AutoPlaySwipeableViews = SwipeableViews;

const useStyles = makeStyles((theme) => ({
  S_stepper_puces: {
    position: "absolute",
    left: "0",
    right: "0",
    width: "auto",
    margin: "-60px auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& div": {
      width: "20px",
      height: "8px",
      backgroundColor: "white",
      margin: "0px 4px",
      borderRadius: "500px",
      transition: "all 0.3s",
      opacity: "0.5",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "& .active": {
      width: "60px",
      height: "8px",
      opacity: "1",
      "&:hover": {
        cursor: "default",
        transform: "rotate(0deg)",
      },
    },
  },
}));

const Swipper1 = (props) => {
  const { elements } = props;
  const [activeStep, setActiveStep] = useState(0);
  const { S_stepper_puces } = useStyles();
  const theme = useTheme();

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (activeStep < elements.length - 1) {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
      } else {
        setActiveStep(0);
      }
    }, 6000);
    return () => {
      clearInterval(interval);
    };
  }, [elements, activeStep]);

  return (
    <div>
      <AutoPlaySwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={6000}
      >
        {elements.map((elem, key) => {
          return elem;
        })}
      </AutoPlaySwipeableViews>

      <div className={S_stepper_puces}>
        {elements.map((item, key) => {
          return (
            <div
              key={key}
              className={key === activeStep ? "active" : ""}
              //onClick={() => handleStepChange(key)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default Swipper1;
