import React, { useState, useEffect } from "react";
import { useStyles } from "./ContactUsStyles";
import ScrollToThis from "../../Components/Simple/ScrollToThis";

import { GetLang, GetMember } from "../../Actions/UserRedux";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";

import SectionTitle from "../../Components/Simple/SectionTitle";

import Snack from "../../Components/Simple/Snack";
import { vEmail } from "../../Functions/Validator";
import { addMessage } from "../../services/ContactService";

const initContact = {
  name: "",
  email: "",
  subject: "",
  message: "",
};

function ContactUs() {
  let lang = GetLang() === "EN";
  const cs = useStyles();
  const [contact, setContact] = useState(initContact);
  const [errors, setErrors] = useState(initContact);
  const [show, setShow] = useState(false);
  let member = GetMember();

  const submit = async () => {
    if (validation()) {
      const sent = await addMessage(contact);
      if (sent.email && sent) {
        setContact({ ...contact, subject: "", message: "" });
        setShow(true);
      }
    }
  };

  const validation = () => {
    if (contact.name === "") {
      setErrors({
        ...errors,
        name: lang ? "name is empty!" : "خانة الأسم فارغة",
      });
      return false;
    } else {
      setErrors({ ...errors });
    }
    if (!vEmail(contact.email)) {
      setErrors({
        ...errors,
        email: lang
          ? "invalid email address!"
          : "تأكد من صحة البريد الألكتروني",
        name: "",
      });
      return false;
    } else {
      setErrors({ ...errors, email: "" });
    }
    if (contact.subject === "") {
      setErrors({
        ...errors,
        subject: lang ? "subject is empty!" : "خانة الموضوع فارغة",
        name: "",
        email: "",
      });
      return false;
    } else {
      setErrors({ ...errors, subject: "" });
    }
    if (contact.message === "") {
      setErrors({
        ...errors,
        message: lang ? "message is empty!" : "خانة المحتوى فارغة",
        subject: "",
        name: "",
        email: "",
      });
      return false;
    }
    setErrors(initContact);
    return true;
  };

  useEffect(() => {
    if (member) {
      setContact({ ...contact, name: member.name, email: member.email });
    } else {
      setContact({ ...contact, name: "", email: "" });
    }
  }, [member]);

  useEffect(() => {
    //console.log(errors);
  }, [errors]);

  return (
    <div className={cs.main + " " + (lang ? cs.en : cs.ar)}>
      <ScrollToThis />

      <Snack show={show}>
        {lang ? "send with success" : "تم الإرسال بنجاح"}
      </Snack>

      <Grid className="grid" container>
        <Grid
          item
          style={{ padding: "0px 20px" }}
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <SectionTitle part1={lang ? "Let’s talk." : "لنتحدث."} />
          <h2>
            {lang
              ? "Feel free to connect with us !"
              : "لا تتردد في الاتصال بنا!"}
          </h2>

          <div className="form">
            <TextField
              className="input"
              error={errors.name !== ""}
              helperText={errors.name}
              value={contact.name}
              onChange={(e) => {
                setContact({ ...contact, name: e.target.value });
              }}
              fullWidth
              label={lang ? "Your name" : "الاسم و اللقب"}
              variant="outlined"
            />
            <TextField
              className="input"
              error={errors.email !== ""}
              helperText={errors.email}
              value={contact.email}
              onChange={(e) => {
                setContact({ ...contact, email: e.target.value });
              }}
              fullWidth
              label={lang ? "Your email" : "البريد الألكتروني"}
              variant="outlined"
            />

            <TextField
              className="input"
              error={errors.subject !== ""}
              helperText={errors.subject}
              value={contact.subject}
              onChange={(e) => {
                setContact({ ...contact, subject: e.target.value });
              }}
              fullWidth
              label={lang ? "Subject" : "الموضوع"}
              variant="outlined"
            />

            <TextField
              className="input"
              error={errors.message !== ""}
              helperText={errors.message}
              value={contact.message}
              onChange={(e) => {
                setContact({ ...contact, message: e.target.value });
              }}
              fullWidth
              label={lang ? "Your message" : "المحتوى"}
              multiline
              rows={6}
              variant="outlined"
            />
            <button className="submit_btn" onClick={submit}>
              {lang ? "Send" : "أرسل"}
            </button>
          </div>
        </Grid>

        <Grid
          item
          style={{ padding: "0px 20px" }}
          xl={6}
          lg={6}
          md={6}
          sm={12}
          xs={12}
        >
          <SectionTitle
            part1={lang ? "Contact" : " إتصل بنا"}
            part2={lang ? "informations." : "عبر"}
          />
          <div className="info">
            <p className="phoneNum">+216 58 339 343</p>
            <p>association.saheb.ettabaa@gmail.com</p>
            <p>4 RUE SIDI ELALOUI ELHALFAOUIN 1006 Tunis, Tunisia</p>
          </div>
          <SectionTitle part1={lang ? "Follow us." : "كل يوم أربعاء"} />
          <br />
          <h2>
            {lang
              ? "Feel free to connect with us !"
              : "بداية من الساعة 7:00 مساءا"}
          </h2>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContactUs;
