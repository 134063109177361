import { firestore } from "../config/firebase";
const messages = firestore.collection("messages");

export const addMessage = async (message) => {
  const resp = await messages.add({
    ...message,
    deleted: false,
    sendDate: new Date().toString(),
  });
  return (await resp.get()).data();
};
