import { firestore /*auth, firebase*/ } from "../config/firebase";
import axios from "axios";
const { REACT_APP_API_HOST } = process.env;
const api = REACT_APP_API_HOST;

const blogStoreCategs = firestore.collection("blogCategs");
export const blogShowCategs = blogStoreCategs.where("deleted", "==", false);

const visit = async (id) => {
  try {
    const res = await axios.post(`${api}/api/blogs/visit/${id}`);
    //console.log(res);
  } catch (error) {
    //console.log(error);
  }
};

export const blogStore = firestore.collection("blog");
export const blogShow = blogStore
  .where("deleted", "==", false)
  .where("show", "==", true);
export const blogHome = blogShow.where("inhome", "==", true);

export const getOneBlog = async (id) => {
  const response = blogShow.where("__name__", "==", id);
  const data = await response.get();
  let oneBlog = null;
  data.docs.forEach((item) => {
    oneBlog = item.data();
  });
  await visit(id);
  return oneBlog;
};

export const getAllCardBlogsHome = async () => {
  const doc = await blogHome.get();
  const blogs = [];
  doc.forEach((blog) => {
    const bl = blog.data();
    blogs.push({
      id: bl.id,
      title_en: bl.title_en,
      title_ar: bl.title_ar,
      author_en: bl.author_en,
      author_ar: bl.author_ar,
      author_img: bl.author_img,
      categs: bl.categs,
      description_en: bl.description_en,
      description_ar: bl.description_ar,
      hashtags: bl.hashtags,
      date: bl.date,
      img: bl.img,
      mob_img: bl.mob_img,
      nbviews: bl.nbviews,
      deleted: bl.deleted,
      show: bl.show,
      inhome: bl.inhome,
      ar: bl.ar,
      en: bl.en,
    });
  });
  return blogs;
};

export const getAllCardBlogs = async (currentCategs) => {
  const doc = await blogShow.get();
  const blogs = [];
  doc.forEach((blog) => {
    const bl = blog.data();
    let isRelated = false;
    bl.categs.forEach((categ) => {
      if (categ.title_en.split(" ").join("_") === currentCategs) {
        isRelated = true;
      }
    });
    if (isRelated) {
      blogs.push({
        id: bl.id,

        title_en: bl.title_en,
        title_ar: bl.title_ar,
        author_en: bl.author_en,
        author_ar: bl.author_ar,
        author_img: bl.author_img,
        description_en: bl.description_en,
        description_ar: bl.description_ar,
        hashtags: bl.hashtags,
        categs: bl.categs,
        date: bl.date,
        img: bl.img,
        mob_img: bl.mob_img,
        nbviews: bl.nbviews,
        deleted: bl.deleted,
        show: bl.show,
        inhome: bl.inhome,
        ar: bl.ar,
        en: bl.en,
      });
    }
  });
  return blogs;
};

export const getAllRelatedCardBlogs = async (categories, idBlog) => {
  const doc = await blogShow.get();
  const blogs = [];

  let categsTexts = "";
  categories.forEach((categ) => {
    categsTexts = `${categsTexts} ${categ.title_en.split(" ").join("_")}`;
  });
  console.log(categories);
  console.log(categsTexts);

  doc.forEach((blog) => {
    const bl = blog.data();
    let isRelated = false;
    bl.categs.forEach((categ) => {
      if (categsTexts.indexOf(categ.title_en.split(" ").join("_")) !== -1) {
        isRelated = true;
      }
    });
    if (isRelated && idBlog !== bl.id) {
      blogs.push({
        id: bl.id,

        title_en: bl.title_en,
        title_ar: bl.title_ar,
        author_en: bl.author_en,
        author_ar: bl.author_ar,
        author_img: bl.author_img,
        description_en: bl.description_en,
        description_ar: bl.description_ar,
        hashtags: bl.hashtags,
        categs: bl.categs,
        date: bl.date,
        img: bl.img,
        mob_img: bl.mob_img,
        nbviews: bl.nbviews,
        deleted: bl.deleted,
        show: bl.show,
        inhome: bl.inhome,
        ar: bl.ar,
        en: bl.en,
      });
    }
  });
  return blogs;
};

export const getRelatedCardBlogs = async (category) => {
  const doc = await blogShow.get();
  const blogs = [];
  doc.forEach((blog) => {
    const bl = blog.data();
    blogs.push({
      id: bl.id,
      title_en: bl.title_en,
      title_ar: bl.title_ar,
      author_en: bl.author_en,
      author_ar: bl.author_ar,
      description_en: bl.description_en,
      description_ar: bl.description_ar,
      hashtags: bl.hashtags,
      date: bl.date,
      categs: bl.categs,
      img: bl.img,
      mob_img: bl.mob_img,
      nbviews: bl.nbviews,
      deleted: bl.deleted,
      show: bl.show,
      inhome: bl.inhome,
      ar: bl.ar,
      en: bl.en,
    });
  });
  return blogs;
};
