import { firestore /*auth, firebase*/ } from "../config/firebase";

export const eventStore = firestore.collection("events");

export const eventShow = eventStore
  .where("deleted", "==", false)
  .where("show", "==", true);
export const eventHome = eventShow.where("inhome", "==", true);

export const getOneevent = async (id) => {
  const response = eventShow.where("__name__", "==", id);
  const data = await response.get();
  let oneevent = null;
  data.docs.forEach((item) => {
    oneevent = item.data();
  });
  return oneevent;
};
