import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  en: {
    "& h1,h2": {
      textAlign: "left",
    },
  },
  ar: {
    "& h1,h2,input,label,textarea,p": {
      textAlign: "right",
      direction: "rtl",
    },
    "& .phoneNum": {
      direction: "ltr",
    },
    "& .icons": {
      width: "100% !important",
      justifyContent: "flex-end !important",
      [theme.breakpoints.down("md")]: {
        justifyContent: "center !important",
      },
      "& .icon": {
        margin: "10px 15px !important",
      },
    },
    "& label": {
      right: "30px",

      "&:before": {
        content: '""',

        display: "block",
        position: "absolute",
        right: "-10px",
        background: "#f1f0f0",
        width: "120px",
        height: "20px",
        zIndex: "-1",
      },
    },
    "& .MuiInputLabel-shrink": {
      color: "black !important",
      transform: "translate(90px, -12px) scale(0.75)",
      [theme.breakpoints.down("sm")]: {
        transform: "translate(180px, -12px) scale(0.75)",
      },
      [theme.breakpoints.down("xs")]: {
        transform: "translate(110px, -12px) scale(0.75)",
      },
    },
    "& legend": {
      position: "absolute",
      color: "black !important",
    },
  },
  main: {
    padding: "30px 200px",
    // backgroundColor: theme.palette.black.main,
    [theme.breakpoints.down("md")]: {
      padding: "20px",
    },

    color: "black",

    "& .logo": {
      height: "63px",
      width: "223px",
      display: "block",
      margin: "0px auto 100px auto",

      [theme.breakpoints.down("lg")]: {
        margin: "0px auto 60px auto",
      },
    },

    "& h2": {
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "36px",
      color: "black !important",
      margin: "0px",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },

    "& h1": {
      color: "black !important",
      [theme.breakpoints.down("md")]: {
        textAlign: "center",
      },
    },

    "& .form": {
      margin: "40px 0px",
      "& fieldset": {
        border: "solid 1px black !important",
      },
      "& *": {
        color: "black",
      },

      "& .input": {
        margin: "10px 0px",

        "& *": {
          fontSize: "20px",
          [theme.breakpoints.down("md")]: {
            fontSize: "14px",
          },
        },
        "&:hover": {
          "& fieldset": {
            border: "solid 2px black !important",
          },
        },
      },

      "& .btn": {
        [theme.breakpoints.down("md")]: {
          fontSize: "18px",
          padding: "5px 0px",
        },
      },
    },

    "& .info": {
      margin: "50px 0px",

      "& p": {
        fontWeight: "400",
        fontSize: "18px",
        lineHeight: "27px",
        [theme.breakpoints.down("md")]: {
          textAlign: "center",
        },
      },
    },

    "& .icons": {
      margin: "20px 0px",
      width: "200px",
      display: "flex",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        margin: "20px auto",
      },

      "& .icon": {
        color: "black",
        padding: "0px",
        margin: "10px 0px",
        "&:hover": {
          color: theme.palette.green.main,
        },
      },
    },

    "& .submit_btn": {
      backgroundColor: "black",
      color: "white !important",
      border: "solid 1px black",
      fontSize: "40px !important",
      fontFamily: "Abhaya",
      padding: "10px 40px",
      borderRadius: "0px",
      margin: "auto",
      display: "block",
      width: "100%",
      cursor: "pointer",
      borderRadius: "5px",

      transition: "all 0.2s",
      "&:hover": {
        color: "white",
        backgroundColor: "#13613A",
      },
    },
  },
}));
