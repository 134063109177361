import React from "react";
import { useStyles } from "./styles";
import Grid from "@material-ui/core/Grid";
import Container1 from "../../../Components/Simple/Container1";
//import Hidden from "@material-ui/core/Hidden";

import { ZoomIn } from "../../../Components/Animations/Zoom";
import { FadeRight } from "../../../Components/Animations/Fade";
import { GetLang /*GetScreenSize*/ } from "../../../Actions/UserRedux";
import { useHistory } from "react-router";

import BlackButton from "../../../Components/Simple/BalckButton";
import { NavLink } from "react-router-dom";

let img =
  "https://res.cloudinary.com/nadi-palestine/image/upload/v1647438808/ManualDep/TopCarossel/pc/photo_2022-03-13_17-49-17_j1qycp.jpg";

const WhyPalestineForum = () => {
  const css = useStyles();
  let lang = GetLang() === "EN";
  const history = useHistory();
  const goToAbout = () => {
    history.push("/about");
  };

  //let width = GetScreenSize();
  return (
    <div className={css.S_wpf}>
      <Container1>
        <Grid container spacing={0} direction={!lang ? "row-reverse" : "row"}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className={lang ? css.S_Left : css.S_Right}
          >
            <FadeRight delay={0} duration={1000}>
              <div className={css.S_Text}>
                <h2 className={css.S_h2 + ` ${!lang ? "ar_h2" : ""}`}>
                  <NavLink to="/about">
                    {lang ? <>Palestine Forum</> : <>منتدى فلسطين</>}
                  </NavLink>
                </h2>
                <p className={!lang ? "ar_p" : "en_p"}>
                  {lang ? (
                    <>
                      The Havean’s Gate, the journey of the Prophets, Our
                      direction of worship (AL-keblah) , the cause of Islam and
                      Human. We came up with a clear message, complementing the
                      noble morals, but how would it happen ?
                    </>
                  ) : (
                    <>
                      بوابة السماء، مسرى الأنبياء، قبلتنا، قضية الإسلام و
                      الإنسان. جئنا برسالة واضحة تتمة مكارم الأخلاق لكن أنّى ذلك
                      و القلب يتألّم و الفؤاد يئن .
                    </>
                  )}
                </p>
                <p className={!lang ? "ar_p" : "en_p"}>
                  {lang ? (
                    <>
                      While the heart is moaning and groaning. Palestine is the
                      heart of a wounded moaning Nation.
                    </>
                  ) : (
                    <>
                      فلسطين قلب الأمة جريحة تئن، و من المكارم أن تُردّ المظالم
                      و تُستردّ الحقوق.
                    </>
                  )}
                </p>
                <div className={css.moreBtn}>
                  <BlackButton onClick={goToAbout}>
                    {lang ? <>VIEW MORE</> : <>عرض المزيد</>}
                  </BlackButton>
                </div>
              </div>
            </FadeRight>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={6}
            xl={6}
            className={lang ? css.S_Right : css.S_Left}
          >
            <ZoomIn delay={0} duration={1000}>
              <div
                className={css.S_image}
                style={{ backgroundImage: `url(${img})` }}
              />
            </ZoomIn>
          </Grid>
        </Grid>
      </Container1>
    </div>
  );
};

export default WhyPalestineForum;
