import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  S_Gallery: {
    padding: "80px 0px 20px 0px",
    margin: "50px 0px 0px 0px",

    fontFamily: "roxbor",
    color: "white",

    "& .ar_h2": {
      fontFamily: "Hudhud",
      textAlign: "right",
      fontSize: "60px",
      margin: "0px 50px -50px 0px",

      [theme.breakpoints.down("sm")]: {
        margin: "0px",
        textAlign: "center",
      },
    },
  },

  S_h2: {
    fontSize: "40px",
    fontWeight: "200",
    margin: "0px 0px 0px 50px",
    position: "relative",
    zIndex: "1",
    color: "black",

    "& *": {
      textDecoration: "none",
      color: "black",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "30px",
      margin: "0px",
      textAlign: "right",
    },
  },
}));
