import React from "react";
import { makeStyles } from "@material-ui/core/styles";

import { Grid } from "@material-ui/core";
import ContactItem from "../Components/Cards/ContactItem";

import { GetLang } from "../Actions/UserRedux";
import { MdEmail, MdPhoneInTalk } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  contact: {
    backgroundColor: "black",
    width: "100%",
    padding: "44px 95px 20px 95px",

    [theme.breakpoints.down("sm")]: {
      borderRadius: "100px 0px 0px 0px",
    },

    "& *": {
      direction: "ltr",
    },

    "& .ar": {
      "& *": {
        textAlign: "right",
      },
      "& h2": {
        fontFamily: "Hudhud",
      },
      "& h4": {
        fontFamily: "Hudhud",
        fontSize: "30px",
      },
      "& .S_signature": {
        borderRight: "solid #FB6459 3px",
        borderLeft: "solid #FB6459 0px",
        padding: "0px 20px 0px 00px",
      },
    },

    "& .en": {
      "& h2": {
        fontFamily: "HammersmithOne",
      },
      "& h4": {
        fontFamily: "robox",
      },
      "& .S_signature": {
        borderLeft: "solid #FB6459 3px",
        borderRight: "solid #FB6459 0px",
        padding: "0px 0px 0px 20px",
      },
    },

    [theme.breakpoints.down("lg")]: {
      padding: "20px 50px 20px 50px",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "20px",
    },

    color: "white",

    "& img": {
      marginTop: "50px",

      [theme.breakpoints.down("md")]: {
        width: "150px",
      },

      [theme.breakpoints.down("sm")]: {
        display: "block",
        margin: "auto",
        marginTop: "20px",
      },
    },

    "& .header": {
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "row-reverse",
      height: "100%",
      width: "100%",
    },

    "& .copyright": {
      fontWeight: "400",
      color: "white",
      fontSize: "16px",
      textAlign: "center !important",
      margin: "0px",
      letterSpacing: "1px",
      wordSpacing: "4px",
    },

    "& .S_signature": {
      color: "white",
      margin: "20px 0px",

      [theme.breakpoints.down("xs")]: {
        borderBottom: "solid #FB6459 3px !important",
        borderLeft: "solid #FB6459 0px !important",
        borderRight: "solid #FB6459 0px !important",
        padding: "0px 0px 20px 0px !important",
        margin: "20px auto",
        "& .ar,.en": {
          textAlign: "center",
        },
      },

      "& .ar": {
        margin: "0px",
        padding: "0px",
        fontFamily: "Hudhud",
        fontSize: "35px",
      },

      "& .en": {
        margin: "0px",
        padding: "0px",
        fontFamily: "HammersmithOne",
        fontSize: "23px",
      },
    },
  },

  timing: {
    padding: "30px 0px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "column",

    "& .time": {
      fontFamily: "AraHamah !important",
      fontSize: "24px",
      fontWeight: "600",
      color: "white",
      padding: "6px 24px",
      borderRadius: "500px",
    },

    "& .red": {
      backgroundColor: "#FB6459",
      transform: "rotate(5deg)",
    },

    "& .grey": {
      backgroundColor: "#343535",
      transform: "rotate(-2deg) translateY(6px)",
    },
  },

  externals: {
    "& h4": {
      margin: "0px 0px 20px 0px",
      textAlign: "center !important",
    },
    "& .rxs": {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "& .rx": {
        margin: "0px 5px",
        backgroundColor: "#FB6459",
        height: "40px",
        width: "40px",
        borderRadius: "500px",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "black",
        fontSize: "25px",
        cursor: "pointer",
      },
    },
  },

  pages: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "column",
    width: "100%",
    "& *": {
      textAlign: "right",
    },

    "& h4": {
      fontFamily: "Hudhud",
      margin: "0px 0px",
      fontSize: "28px",
    },

    "& a": {
      color: "white",
      textDecoration: "none",
    },

    "& p": {
      fontWeight: "100",
      fontSize: "24px",
      lineHeight: "18px",
      fontFamily: "AraHamah",
      cursor: "pointer",
    },

    "& .parts": {
      width: "100%",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-end",

      "& div": {
        padding: "0px 0px 0px 20px",
      },
    },
  },

  sign_and_follow: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },

  footer: {
    "& .pc": {
      display: "block",
      [theme.breakpoints.down("sm")]: {
        display: "none",
      },
    },

    "& .mobile": {
      display: "none",
      [theme.breakpoints.down("sm")]: {
        display: "block",
      },
    },
  },
}));

function FooterPc() {
  const cs = useStyles();
  let lang = GetLang() === "EN";

  return (
    <div className={cs.contact}>
      <div className={lang ? "en" : "ar"}>
        <div className="header">
          <div className={"S_signature"}>
            <h4 className="ar">منتدى فلسطين</h4>
            <h4 className="en">PALESTINE FORUM</h4>
          </div>

          <div className={cs.timing}>
            <div className="time red">التوقيت</div>
            <div className="time grey">
              كل يوم إربعاء بداية من الساعة 7 مساء
            </div>
          </div>

          <div className={cs.externals}>
            <h4>تابعونا</h4>
            <div className="rxs">
              <div className="rx">
                <MdEmail />
              </div>
              <div className="rx">
                <IoLocationSharp />
              </div>
              <div className="rx">
                <FaFacebookF />
              </div>
              <div className="rx">
                <MdPhoneInTalk />
              </div>
            </div>
          </div>
        </div>
        <p className="copyright">
          © Copyright {new Date().getFullYear()} - Palestine Forum
        </p>
      </div>
    </div>
  );
}

function FooterMobile() {
  const cs = useStyles();

  return (
    <div className={cs.contact}>
      <div className={cs.sign_and_follow}>
        <div className={"S_signature"}>
          <h4 className="ar">منتدى فلسطين</h4>
          <h4 className="en">PALESTINE FORUM</h4>
        </div>
      </div>

      <div className={cs.timing}>
        <div className="time red">التوقيت</div>
        <div className="time grey">كل يوم إربعاء بداية من الساعة 7 مساء</div>
      </div>

      <div className={cs.externals}>
        <h4>تابعونا</h4>
        <div className="rxs">
          <div className="rx">
            <MdEmail />
          </div>
          <div className="rx">
            <IoLocationSharp />
          </div>
          <div className="rx">
            <FaFacebookF />
          </div>
          <div className="rx">
            <MdPhoneInTalk />
          </div>
        </div>
      </div>
    </div>
  );
}

const Footer = () => {
  const cs = useStyles();
  return (
    <div className={cs.footer}>
      <div className="pc">
        <FooterPc />
      </div>
      <div className="mobile">
        <FooterMobile />
      </div>
    </div>
  );
};

export default Footer;

const footersAR = [
  {
    title: "الصفحات",
    isLink: true,
    same: true,
    items: [
      { text: "الرئيسية", link: "/home" },
      { text: "عن المنتدى", link: "/about" },
      { text: "المدونة", link: "/blogs" },
      { text: "المعرض", link: "/gallery" },
      { text: "ذاكرة المنتدى", link: "/forum_memory" },
      { text: "الفعاليات", link: "/events" },
    ],
  },
];

const footersEN = [
  {
    title: "SiteMap",
    isLink: true,
    same: true,
    items: [
      { text: "Home", link: "/home" },
      { text: "about", link: "/about" },
      { text: "blogs", link: "/blogs" },
      { text: "gallery", link: "/gallery" },
      { text: "forum memory", link: "/forum_memory" },
      { text: "events", link: "/events" },
    ],
  },
];
