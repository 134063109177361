import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import Container1 from "../../../Components/Simple/Container1";
import Swipper2 from "../../../Components/Complex/Swipper2";
import { TableToNb } from "../../../Functions/functions";
import GalleryCard from "../../../Components/Cards/GalleryCards/GalleryCard";

import { FadeDown } from "../../../Components/Animations/Fade";
import { GetLang, GetScreenSize } from "../../../Actions/UserRedux";
import Spinner from "../../../Components/Simple/Spinner";
import { useHistory } from "react-router";
import { useStyles } from "./styles";
import { NavLink } from "react-router-dom";

const OurGallery = ({ gallery }) => {
  const css = useStyles();
  const [gals, SetGals] = useState([...gallery]);
  let lang = GetLang() === "EN";
  let width = GetScreenSize();

  useEffect(() => {
    if (width === "lg" || width === "xl") SetGals(TableToNb(gallery, 4));
    else if (width === "sm" || width === "md") SetGals(TableToNb(gallery, 2));
    else if (width === "xs") SetGals(TableToNb(gallery, 1));
  }, [width, gallery]);

  const history = useHistory();

  const gotopage = () => {
    history.push("/gallery");
  };

  return (
    <div className={css.S_Gallery}>
      <h2 className={css.S_h2 + (!lang ? " ar_h2" : "")}>
        <FadeDown delay={0} duration={1000}>
          <NavLink to="/gallery">
            {lang ? (
              <>
                Our <br /> Gallery
              </>
            ) : (
              <>المعرض</>
            )}
          </NavLink>
        </FadeDown>
      </h2>

      {gals.length ? (
        <Swipper2
          gotopage={gotopage}
          Component={OurGalleryGroup}
          elements={gals}
          color="white"
        />
      ) : (
        <Spinner />
      )}
    </div>
  );
};

const OurGalleryGroup = (props) => {
  let lang = GetLang() === "EN";
  const { elem } = props;
  return (
    <div>
      <Container1>
        <Grid
          container
          spacing={0}
          style={{ overflow: "hidden" }}
          direction={!lang ? "row-reverse" : "row"}
        >
          {elem.map((ga, key) => {
            return (lang && ga.en) || (!lang && ga.ar) ? (
              <Grid item key={key} lg={3} md={6} sm={6} xs={12}>
                <GalleryCard gal={ga} />
              </Grid>
            ) : null;
          })}
        </Grid>
      </Container1>
    </div>
  );
};

export default OurGallery;
