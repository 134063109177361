import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#01773B",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    height: "200px",
    width: "50px",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transform: "scale(2)",
    "& div": {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
}));

export default function Spinner() {
  const classes = useStyles();
  return (
    <ThemeProvider theme={outerTheme}>
      <div className={classes.root}>
        <div>
          <CircularProgress />
        </div>
      </div>
    </ThemeProvider>
  );
}
