import React, { useState, useEffect } from "react";
import { useStyles } from "./memorycard.styles";
import { GetLang } from "../../../Actions/UserRedux";

import { useHistory } from "react-router";
import { getDateEn, getDateAr } from "../../../Functions/DateFunctions";

import Liked from "../../../Assets/svgs/filled_heart.svg";
import NotLiked from "../../../Assets/svgs/empty_heart.svg";
import { like_dislike_memo } from "../../../services/ForMemServices";
import { useSelector } from "react-redux";
import { toast } from "react-hot-toast";

function MemoryCard({ memory }) {
  const { title_en, title_ar, img, id, date, categs, tags_ar, likes } = memory;
  let lang = GetLang() === "EN";
  const user = useSelector((state) => state.member);
  const [liked, setLiked] = useState(false);

  const history = useHistory();
  const gotoMemo = () => {
    history.push("/memory/" + id);
  };

  const gotocategs = () => {
    history.push("/forum_memory/" + categs[0]?.title_en.split(" ").join("_"));
  };

  const gototag = () => {
    history.push("/forum_memorys/" + tags_ar);
  };

  useEffect(() => {
    setLiked((likes || [])?.indexOf(user?.id) !== -1 || false);
  }, [likes, user]);

  const like_dislike = () => {
    if (user) {
      let new_likes = likes || [];
      if ((likes || [])?.indexOf(user?.id) === -1 || false) {
        new_likes = [...new_likes, user.id];
      } else {
        new_likes = (likes || [])?.filter((lk) => lk !== user?.id);
      }
      like_dislike_memo(id, new_likes);
    } else {
      toast.error("قم بالأشتراك أولا");
    }
  };

  const css = useStyles();
  return (
    <div className={`${css.main} ${lang ? css.main_en : css.main_ar} `}>
      <div className="title" onClick={gotoMemo}>
        <p>{lang ? title_en : title_ar}</p>
      </div>

      <div className="container_img" onClick={gotoMemo}>
        <img className="img" src={img} alt={title_en} />
      </div>

      <div className="details">
        <div className="tag_and_date">
          <span className="date">
            {lang ? getDateEn(date) : getDateAr(date)}
          </span>
          <span onClick={gototag} className="tag">
            {tags_ar || ""}
          </span>
        </div>
        <div className="categ_and_likes">
          <span className="likes">
            <div
              onClick={like_dislike}
              className={false ? "like liked" : "like"}
            >
              {liked ? (
                <img src={Liked} alt="heart" className="icon" />
              ) : (
                <img src={NotLiked} alt="heart" className="icon" />
              )}
            </div>
            <div className="value"> {likes?.length || 0} </div>
          </span>
          <span onClick={gotocategs} className="categ">
            {lang ? categs[0]?.title_en : categs[0]?.title_ar}
          </span>
        </div>
      </div>
    </div>
  );
}

export default MemoryCard;
