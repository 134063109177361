import React from "react";
import { useStyles } from "./BlogsNav.styles";
import CloseIcon from "@material-ui/icons/Close";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { GetLang } from "../../../Actions/UserRedux";
import { blogShowCategs } from "../../../services/BlogServices";
import { useHistory } from "react-router";

function BlogsNav({ navShrink, close }) {
  const css = useStyles();
  let lang = GetLang() === "EN";
  const history = useHistory();

  let title = lang ? "BLOG" : "المدونة";
  let subTitle = lang
    ? "Get to know Palestine closely"
    : "تعرّف على فلسطين عن كثب";

  const [categories = []] = useCollectionData(blogShowCategs, {
    idField: "id",
  });

  const GoToBlogsPage = (type = "") => {
    type = type.split(" ").join("_");
    history.push("/blogs/" + type);
  };

  return (
    <div
      onMouseLeave={close}
      className={`
      ${css.main} 
      ${navShrink && css.shrink}
      ${lang ? css.sub_nav_eng : css.sub_nav_ar}
      `}
    >
      <div className="container">
        <div className="titles_part">
          <h1>{title}</h1>
          <h3>{subTitle}</h3>
        </div>
        <div className="categs_part">
          <div className="categs">
            {categories.map((categ) => {
              return (
                <p
                  onClick={() => {
                    GoToBlogsPage(categ.title_en);
                  }}
                  key={categ.id}
                >
                  {lang ? categ.title_en : categ.title_ar}
                </p>
              );
            })}
          </div>
        </div>
      </div>
      <CloseIcon className={css.close} onClick={close} />
    </div>
  );
}

export default BlogsNav;
