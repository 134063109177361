import { makeStyles, useTheme } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  S_container: {
    height: "656px",
    width: "100%",

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },

    "& .body": {
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        alignItems: "flex-end",
        justifyContent: "flex-end",
      },

      height: " 656px",
    },
  },

  S_img: {
    backgroundSize: "cover",
    backgroundPosition: "100% 25%",
    position: "relative",
    height: "656px",
    width: "100%",
    margin: "0px",
    overflow: "auto",
    padding: "0px",

    [theme.breakpoints.down("xs")]: {
      backgroundPosition: "50% 25%",
    },

    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      opacity: 0.4,
      background: "#000",
    },
  },

  S_vertical: {
    fontFamily: "Abhaya",
    fontSize: "18px",
    width: "450px",
    textAlign: "center",
    color: "white",
    transform: "translateX(-47%) translateY(350px) rotate(270deg)",
  },

  S_content: {
    fontFamily: "roxbor",
    color: "white",
    textAlign: "center",
    width: "100%",
    margin: "230px 0px 0px 0px",
  },

  S_ar_content: {
    margin: "200px 0px 0px 0px",
  },

  S_h1: {
    fontSize: "90px",
    fontWeight: "300",
    lineHeight: "1.2",
    width: "100%",
    margin: "0px",
    padding: "0px",

    [theme.breakpoints.down("md")]: {
      fontSize: "75px",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "50px",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: "28px",
    },
  },
  S_h2: {
    fontSize: "35px",
    fontWeight: "500",
    padding: "0px 40px",
    margin: "0px",
    width: "100%",

    [theme.breakpoints.down("md")]: {
      fontSize: "30px",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: "25px",
    },

    [theme.breakpoints.down("xs")]: {
      fontSize: "18px",
    },
  },

  white_card: {
    borderTop: "solid 2px black",
    position: "relative",
    width: "320px",
    backgroundColor: "white",
    padding: "25px 30px",

    "& *": {
      margin: "0px",
      padding: "0px",
    },

    "& div": {
      "& h1": {
        fontFamily: "roxbor",
        fontSize: "35px",
        fontWeight: "500",
      },

      "& h2": {
        fontFamily: "roxbor",
        fontSize: "18px",
        fontWeight: "500",
      },

      "& h3": {
        fontFamily: "Abhaya",
        fontSize: "11px",
        fontWeight: "300",
        textAlign: "center",
        margin: "20px 0px",
      },
    },

    "& .ar": {
      //textAlign: "right",
      fontWeight: "500",
      //backgroundColor: "blue",
      textAlign: "center",

      "& h1": {
        fontSize: "35px",
        //backgroundColor: "red",
        fontFamily: "Hudhud !important",
        fontWeight: "800",
      },

      "& h2": {
        fontSize: "20px",
        //backgroundColor: "yellow",
        fontWeight: "300",
        fontFamily: "AraHamah",
      },

      "& h3": {
        fontFamily: "AraHamah !important",
        //backgroundColor: "green",
        fontSize: "20px",
        fontWeight: "300",
        margin: "20px 0px",
      },
    },
  },

  timing: {
    width: "100%",
    padding: "50px 90px",
    position: "absolute",

    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
    flexDirection: "column",

    "& .time": {
      fontFamily: "AraHamah !important",
      fontSize: "28px",
      fontWeight: "600",
      color: "white",
      border: "solid 1px black",
      padding: "8px 30px",
      borderRadius: "500px",
    },

    "& .red": {
      backgroundColor: "#FB6459",
      transform: "rotate(5deg)",
    },

    "& .grey": {
      backgroundColor: "#343535",
      transform: "rotate(-2deg) translateY(6px)",
    },
  },

  mobile_v: {
    display: "none",
    width: "100%",
    backgroundColor: "#FA6559",
    padding: "10px 15px",
    borderRadius: "0px 0px 100px 0px",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },

    "& .rxs": {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",

      "& .rx": {
        backgroundColor: "white",
        color: "#FA6559",
        height: "25px",
        width: "25px",
        borderRadius: "500px",
        margin: "0px 3px",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& .icon": {
          fontSize: "17px",
        },
      },
    },

    "& h2": {
      fontSize: "40px",
      fontFamily: "Hudhud",
      fontWeight: "500",
      textAlign: "center",
      margin: "20px 0px 0px 0px",
      lineHeight: "1.4",
    },

    "& h4": {
      fontSize: "20px",
      fontFamily: "Hudhud",
      fontWeight: "400",
      textAlign: "center",
      margin: "0px",
    },

    "& button": {
      border: "none",
      outline: "none",
      backgroundColor: "white",
      color: "black",
      fontSize: "18px",
      padding: "5px 8px",
      borderRadius: "8px",
      fontFamily: "AraHamah",
    },
  },

  inhome: {
    backgroundColor: "#0F0E0C",
  },
}));
