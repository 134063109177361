import React, { useState, useEffect } from "react";

import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router";
import AddCircleIcon from "@material-ui/icons/AddCircle";

import { GetLang } from "../../Actions/UserRedux";

import Container1 from "../../Components/Simple/Container1";

import ScrollToThis from "../../Components/Simple/ScrollToThis";

import { blogShowCategs } from "../../services/BlogServices";
import { useCollectionData } from "react-firebase-hooks/firestore";

import { useStyles } from "./OneBlog/BlogStyles";

const useStyles1 = makeStyles((theme) => ({
  main: {
    padding: "80px 0px",

    [theme.breakpoints.down("xs")]: {
      padding: "20px 0px",
    },

    textAlign: "right",
    "& h1": {
      display: "inline",
      color: "black",
      fontSize: "60px",
      // borderBottom: "solid 5px black",
      padding: "10px 30px",
      margin: "0px",

      [theme.breakpoints.down("sm")]: {
        display: "block",
        fontSize: "35px",
        marginBottom: "30px",
      },
    },

    "& a": {
      textDecoration: "none",
    },

    // **************************** AR - EN ********************************

    "& .ar": {
      "& h1": {
        fontFamily: "Hudhud",
        fontWeight: "500",
      },
    },

    "& .en": {
      "& h1": {
        fontFamily: "roxbor",
      },
    },
  },
  items: {
    margin: "20px 0px",
  },

  card: {
    width: "23%",
    height: "100px",
  },
}));

function CategBlogs() {
  const cs = useStyles1();
  const cs2 = useStyles();
  let lang = GetLang() === "EN";
  const history = useHistory();

  const [categories = []] = useCollectionData(blogShowCategs, {
    idField: "id",
  });
  const [grouped_categs, set_grouped_categs] = useState([]);

  const GoToBlogsPage = (type = "") => {
    type = type.split(" ").join("_");
    history.push("/blogs/" + type);
  };

  useEffect(() => {
    let new_array = [];
    let grou_of2 = [];
    categories.forEach((categ, key) => {
      grou_of2.push(categ);
      if (key % 2 === 1) {
        new_array.push([...grou_of2]);
        grou_of2 = [];
      }
    });
    set_grouped_categs([...new_array]);
  }, [categories]);

  return (
    <div className={cs.main}>
      <ScrollToThis />
      <div className={lang ? "en" : " ar"}>
        <h1>{lang ? "All Of Our Blogs" : "المدونة"}</h1>

        <div className={cs2.cards_for_selections}>
          {grouped_categs.map((categs, key) => {
            let sizeA = "h50";
            let sizeB = "h50";
            if (key === 0) {
              sizeA = "h25";
              sizeB = "h75";
            } else if (key === 3) {
              sizeA = "h75";
              sizeB = "h25";
            }

            return (
              <div key={key} className="group_2">
                <div
                  onClick={() => {
                    GoToBlogsPage(categs[1].title_en);
                  }}
                  className={`card ${sizeA} ${lang ? "card_en" : "card_ar"}`}
                >
                  {lang ? categs[1].title_en : categs[1].title_ar}
                  <AddCircleIcon className="icon" />
                </div>
                <div
                  onClick={() => {
                    GoToBlogsPage(categs[0].title_en);
                  }}
                  className={`card ${sizeB} ${lang ? "card_en" : "card_ar"}`}
                >
                  {lang ? categs[0].title_en : categs[0].title_ar}
                  <AddCircleIcon className="icon" />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default CategBlogs;
