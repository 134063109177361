import { firestore /*auth, firebase*/ } from "../config/firebase";

export const memoryStore = firestore.collection("ForumMemory");
export const memoryShow = memoryStore
  .where("deleted", "==", false)
  .where("show", "==", true);

export const memoryHome = memoryStore
  .where("deleted", "==", false)
  .where("show", "==", true)
  .where("inhome", "==", true);

export const getOneMemo = async (id) => {
  const response = memoryShow.where("__name__", "==", id);
  const data = await response.get();
  let oneMem = null;
  data.docs.forEach((item) => {
    oneMem = item.data();
  });
  return oneMem;
};

export const like_dislike_memo = async (memo_id, likes) => {
  await memoryStore.doc(memo_id).update({ likes });
};

const memoryStoreCategs = firestore.collection("ForumMemoryCategs");
export const memoryShowCategs = memoryStoreCategs.where("deleted", "==", false);
