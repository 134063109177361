import React from "react";

import { useStyles } from "./AllEventsStyles";
import Grid from "@material-ui/core/Grid";
import { useHistory } from "react-router-dom";
import Spinner from "../../Components/Simple/Spinner";

import { GetLang } from "../../Actions/UserRedux";
import Container1 from "../../Components/Simple/Container1";

import { ZoomIn } from "../../Components/Animations/Zoom";
import ScrollToThis from "../../Components/Simple/ScrollToThis";
import EventCard from "../../Components/Cards/EventCard/EventCard";
import { eventShow } from "../../services/EventsServices";
import { useCollectionData } from "react-firebase-hooks/firestore";

function AllEvents() {
  const [events] = useCollectionData(eventShow, { idField: "id" });
  const cs = useStyles();
  const history = useHistory();
  let lang = GetLang() === "EN";

  const goto = (link) => {
    history.push(link);
  };

  return (
    <div className={cs.main}>
      <ScrollToThis />
      <Container1>
        <div style={{ overflow: "hidden" }} className={lang ? "en" : ""}>
          <h1>{lang ? "Our Events" : "الغعاليات"}</h1>
          <br />
          <br />
          <br />
          <br />
          {events ? (
            <>
              {events.map((card, key) => {
                return (
                  <ZoomIn key={key}>
                    <EventCard elem={card} />
                  </ZoomIn>
                );
              })}
            </>
          ) : (
            <Spinner />
          )}
        </div>
      </Container1>
    </div>
  );
}

export default AllEvents;
