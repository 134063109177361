import { auth, firestore, firebase } from "../config/firebase";
import { signOut } from "firebase/auth";
import axios from "axios";

const members = firestore.collection("members");

const { REACT_APP_API_HOST } = process.env;
const api = REACT_APP_API_HOST;

const connect = async () => {
  try {
    const res = await axios.post(`${api}/api/contacts/connected`);
  } catch (error) {
    //console.log(error);
  }
};

const disconnect = async () => {
  try {
    const res = await axios.post(`${api}/api/contacts/disconnected`);
    //console.log(res);
  } catch (error) {
    //console.log(error);
  }
};

export const SignInFn = async () => {
  const provider = new firebase.auth.GoogleAuthProvider();
  const user = await auth.signInWithPopup(provider);
  const userInfo = user.additionalUserInfo;
  const profile = userInfo.profile;
  const member = {
    id: user.user.uid,
    name: profile.name,
    img: auth.currentUser.photoURL,
    email: profile.email,
    deleted: false,
    joinDate: new Date().toString(),
    blocked: false,
  };
  if (userInfo.isNewUser) {
    members.add({ ...member });
  }
  await connect();
  return member;
};

export const logout = async () => {
  await signOut(auth);
  await disconnect();
  return null;
};
