import { makeStyles } from "@material-ui/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    height: "300px",
    width: "100vw",
    backgroundColor: "#BB4541",
    position: "fixed",
    top: "110px",
    zIndex: "10",

    "& .container": {
      width: "100%",
      height: "100%",
      padding: "0px 100px",

      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "row-reverse",

      "& .titles_part": {
        width: "25%",

        "& h1": {
          margin: "0px",
          padding: "0px",

          fontSize: "58px",
          fontWeight: "900",
        },
        "& h3": {
          margin: "0px",
          padding: "0px",

          fontSize: "25px",
        },
      },

      "& .categs_part": {
        width: "75%",

        "& .categs": {
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          flexWrap: "wrap",
        },

        "& p": {
          width: "25%",
          margin: "20px 0px",
          padding: "0px",

          textAlign: "center",
          fontSize: "25px",
          color: "white",
          cursor: "pointer",
        },
      },
    },
  },
  shrink: {
    top: "80px",
  },

  close: {
    position: "absolute",
    top: "20px",
    left: "20px",
    fontSize: "50px",
    color: "white",
    cursor: "pointer",
  },

  sub_nav_eng: {
    fontFamily: "Belleza",
    textAlign: "left",

    "& .container": {
      flexDirection: "row",
    },
  },

  sub_nav_ar: {
    fontFamily: "Tajawal",
    textAlign: "right",
  },
}));
