import React, { useRef } from "react";
import useOnScreen from "../../Functions/UseOnScreen";
import { useHistory } from "react-router-dom";

import { useStyles } from "./AboutUsStyles";
import { GetLang } from "../../Actions/UserRedux";
import ScrollToThis from "../../Components/Simple/ScrollToThis";
import PersonCard from "../../Components/Cards/PersonCard";
import { Button } from "@material-ui/core";
import YouTubeVideo from "../../Components/Simple/YouTubeVideo/YouTubeVideo";

import { useCollectionData } from "react-firebase-hooks/firestore";
import { teamShow } from "../../services/TeamServices";

function AboutUs() {
  const cs = useStyles();
  const ref = useRef();
  const isVisible = useOnScreen(ref);
  let lang = GetLang() === "EN";
  const history = useHistory();
  const [teams] = useCollectionData(teamShow, { idField: "id" });

  const goTo = (url) => {
    history.push(url);
  };
  return (
    <>
      <div className={cs.AboutUs}>
        <ScrollToThis />
        {/******************* the groups ********************/}
        <div className={`group1 ${lang ? "gen" : ""}`}>
          <div className="txt">
            <h1 className={lang ? "en" : "ar"}>
              {lang ? about.title_en : about.title_ar}
            </h1>
            <hr />
            <p className={lang ? "en" : "ar"}>
              {lang ? about.def_en : about.def_ar}
            </p>
          </div>

          <div className="video_container">
            <YouTubeVideo src={"https://www.youtube.com/watch?v=BVSGbOyfiR0"} />
          </div>
        </div>

        {/* <div className="group2">
          <div className="txt">
            <h1 className={lang ? "en" : "ar"}>
              {lang ? goals.title_en : goals.title_ar}
            </h1>
            <hr />
            <p className={lang ? "en" : "ar"}>
              {" "}
              {lang ? goals.def_en : goals.def_ar}{" "}
            </p>

            <p className={lang ? "en" : "ar"}>
              {lang ? goals.details_en : goals.details_ar}
            </p>
          </div>

          <div className="video_container">
            <YouTubeVideo src={"https://www.youtube.com/watch?v=2FyvcoTyawE"} />
          </div>
        </div> */}

        {/******************* the team ********************/}
        <div className="team" ref={ref}>
          <h1 className={"team_txt " + (lang ? "en" : "ar")}>
            {lang ? <span>Our Team</span> : <span>فريقنا</span>}{" "}
            <hr className={isVisible ? "extend" : "no"} />
          </h1>

          <div className="persons">
            {(teams ? teams : []).map((person, key) => {
              return (
                <PersonCard
                  lang={lang}
                  isVisible={isVisible}
                  key={key}
                  person={person}
                />
              );
            })}
          </div>
        </div>
        <div className="get_in_touch">
          <Button
            style={{ borderRadius: "20px" }}
            onClick={() => {
              goTo("contact");
            }}
            variant="outlined"
            className={`contact_btn ` + (!lang ? " btn_ar " : "")}
          >
            {lang ? <span>Contact Us</span> : <span>اتصل بنا</span>}
          </Button>
        </div>
      </div>
    </>
  );
}

export default AboutUs;

const about = {
  title_ar: "منتدى فلسطين",
  title_en: "Palestine Forum",
  def_ar: `
  بوابة السماء، مسرى الأنبياء، قبلتنا، قضية الإسلام و الإنسان.
  جئنا برسالة واضحة تتمة مكارم الأخلاق لكن أنّى ذلك و القلب يتألّم و الفؤاد يئن.
  فلسطين قلب الأمة جريحة تئن، و من المكارم أن تُردّ المظالم و تُستردّ الحقوق.
  و لأن إلف المأساة يكاد يطحنها
  و لأن الغاصب لا يزال يمعن في إنتهاكها
  و لأن الأَكَلَة تتكالب على قصعتها
  فلا بد من خيمة تُصحّح فيها المفاهيم و بسرعة حتّى تحين ساعة النور.
  من أجل ذلك كان لزاما أن تحضر فلسطين و كلّ قضايا الأمة في ثقافتنا : في رحاب منتدى فلسطين`,
  def_en: `The Havean’s Gate, the journey of the Prophets, Our direction of worship (AL-keblah) , the cause of Islam and Human.
  We came up with a clear message, complementing the noble morals, but how would it happen? While the heart is moaning and groaning.
 Palestine is the heart of a wounded moaning Nation. And infact it is noble to redress grievances and restore rights! And because accustoming the tragedy has put our nation at rock bottom and has grinded her. And because the usurper is still violating her. And because the ravage eaters are coveting on her bowl. There must be a tent where concepts and ideas can be corrected as fast as we can until the glimmer of light penetrates this darkness .
 For this, Palestine and all the Ummah’s causes have to be in the center of question of our cultural discourse in the forum of Palestine.`,
  // details_ar: `تُعبر قضايا حقوق الإنسان في مصر – خاصة في سنوات حكم السيسي- عن حوار
  // جدلي بين نظام السلطة والخارج، في غياب شبه تام لأصل القضية: المواطن
  // المصري.`,
  // details_en: `Human rights issues in Egypt - especially during the years of Sisi's rule - express a dialogue
  // A polemic between the system of power and the outside, in the almost complete absence of the origin of the issue: the citizen
  // Egyptian.`,
};

const goals = {
  title_ar: "أهدافنا",
  title_en: "Our Goals",
  def_ar: `ما يميز المشاريع الصغيرة والمتوسطة هو سهولة إنشائها وتأسيسها، فهي
  تعتمد على رأسمال بسيط، بالإضافة إلى سهولة إدارتها كما أنها من أهم
  العوامل التي تسهم في نمو الإقتصاد`,
  def_en: `What distinguishes small and medium enterprises is the ease of establishing and establishing them, as they are
  It depends on a simple capital, in addition to being easy to manage, as it is one of the most important
  Factors that contribute to the growth of the economy`,
  details_ar: `تُعبر قضايا حقوق الإنسان في مصر – خاصة في سنوات حكم السيسي- عن حوار
  جدلي بين نظام السلطة والخارج، في غياب شبه تام لأصل القضية: المواطن
  المصري.`,
  details_en: `Human rights issues in Egypt - especially during the years of Sisi's rule - express a dialogue
  A polemic between the system of power and the outside, in the almost complete absence of the origin of the issue: the citizen
  Egyptian.`,
};
