import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  AboutUs: {
    // backgroundColor: theme.palette.black.main,
    width: "100%",
    padding: "50px 80px 100px 80px",
    color: "black",
    // color: theme.palette.black.text,
    [theme.breakpoints.down("md")]: {
      padding: "50px 30px 100px 30px",
    },

    [theme.breakpoints.down("xs")]: {
      padding: "0px 10px 20px 10px",
    },

    "& .group1": {
      display: "flex",
      flexDirection: "row-reverse",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
    "& .gen": {
      flexDirection: "row",
    },

    "& .group2": {
      display: "flex",
      flexDirection: "row",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column !important",
      },
    },

    "& .video_container": {
      width: "45%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      "& *": {
        width: "100%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },

    "& .txt": {
      padding: "0px 30px 0px 30px",
      width: "55%",
      [theme.breakpoints.down("md")]: {
        padding: "50px 20px 0px 20px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
      [theme.breakpoints.down("xs")]: {
        padding: "20px 20px 0px 20px",
      },
    },

    "& .ar": {
      textAlign: "justify",
    },

    "& .en": {
      textAlign: "justify",
    },

    "& h1.ar,h2.ar": {
      fontFamily: "Hudhud",
      fontWeight: "100",
    },

    "& h1.en,h2.en": {
      fontFamily: "roxbor",
      fontWeight: "100",
      margin: "0px 0px 10px 0px",
    },

    "& h1": {
      fontSize: "50px",
      margin: "0px",
      padding: "0px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        textAlign: "center !important",
      },
    },

    "& h2": {
      fontSize: "40px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        textAlign: "center !important",
      },
    },

    "& hr": {
      backgroundColor: "black",
      width: "100%",
      height: "2px",
      margin: "0px 0px 40px 0px",
      [theme.breakpoints.down("xs")]: {
        margin: "0px 0px 30px 0px",
      },
    },

    "& p": {
      fontFamily: "HSDreamLight",
      fontSize: "30px",

      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
        fontWeight: "300",
        lineHeight: "1.5",
        textAlign: "justify !important",
      },
    },

    "& p.ar": {
      paddingLeft: "10px",
      fontFamily: "Jazera",
      fontSize: "26px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0px",
        fontSize: "20px",
        direction: "rtl",
      },
    },

    "& p.en": {
      fontSize: "25px",
      paddingRight: "10px",
      lineHeight: "1.5",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0px",
        fontSize: "20px",
      },
    },

    // ************************************** Team *******************************

    "& .team": {
      padding: "50px",
      [theme.breakpoints.down("xs")]: {
        padding: "20px",
      },
    },

    "& .team hr": {
      margin: "10px auto 0px auto",
      width: "10%",
      transition: "all 1s",
    },

    "& .team .extend": {
      width: "100%",
    },

    "& .team_txt": {
      margin: "0px auto !important ",
      width: "300px",
      display: "block",
      textAlign: "center",
      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },
    },

    "& .persons": {
      display: "flex",
      justifyContent: "center",
      flexWrap: "wrap",
    },

    "& .contact_btn": {
      backgroundColor: "black",
      color: "white",
      border: "solid 1px black",
      fontSize: "40px !important",
      fontFamily: "Abhaya",
      padding: "10px 40px",
      borderRadius: "0px",
      margin: "auto",
      display: "block",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px !important",
      },

      transition: "all 0.3s",
      "&:hover": {
        transform: "scale(1.1)",
        color: "white",
        backgroundColor: "#13613A",
      },
    },
    "& .btn_ar": {
      fontFamily: "Hudhud",
    },
  },
}));
