import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    height: "450px",
    width: "85%",
    margin: "auto",

    backgroundColor: "#fff",
    border: "solid 1px #a8b3cf33",
    borderRadius: "20px",
    border: "solid 1px #0000001f",

    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",

    [theme.breakpoints.down("sm")]: {
      border: "none",
      height: "400px",
      width: "90%",
      borderRadius: "0px",
      backgroundColor: "rgba(0,0,0,0)",
      borderBottom: "solid 2px black",
    },

    "& .title": {
      width: "100%",
      height: "15%",
      padding: "0px 10px",
      cursor: "pointer",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      lineHeight: "1.3",

      "& p": {
        color: "#0F0E0C",
        fontWeight: "700",
        fontSize: "20px",
        width: "100%",
        padding: "0px",
        margin: "0px",
        textAlign: "center",
        fontFamily: "Hudhud",
      },

      [theme.breakpoints.down("sm")]: {
        height: "10%",
      },
    },

    "& .container_img": {
      width: "100%",
      height: "65%",
      cursor: "pointer",

      [theme.breakpoints.down("sm")]: {
        height: "70%",
      },

      "& .img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },

    "& .details": {
      width: "100%",
      height: "20%",

      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      flexDirection: "column",
      padding: "10px 10px",

      "& .tag_and_date": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "& .date": {
          fontFamily: "Tajawal",
          fontSize: "16px",
          color: "#FB6459",
          fontWeight: "500",
        },

        "& .tag": {
          fontFamily: "Hudhud",
          fontSize: "22px",
          color: "#0F0E0C",
          fontWeight: "500",
          lineHeight: "1.4",
        },
      },

      "& .categ_and_likes": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "& .categ": {
          fontFamily: "Hudhud",
          fontSize: "20px",
          fontWeight: "500",
          backgroundColor: "#51B47B",
          borderRadius: "5px",
          lineHeight: "1.2",
          padding: "5px 1px 0px 1px",
          cursor: "pointer",
        },

        "& .likes": {
          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          "& .like": {
            borderRadius: "500px",
            cursor: "pointer",

            height: "30px",
            width: "30px",

            display: "flex",
            alignItems: "center",
            justifyContent: "center",

            "& img": {
              height: "100%",
              width: "100%",
            },
          },

          "& .liked": {
            color: "#FB6459",
          },

          "& .value": {
            fontFamily: "Tajawal",
            fontSize: "22px",
            padding: "0px 5px",
            fontWeight: "500",
            color: "#000",
          },
        },
      },
    },
  },

  main_ar: {
    textAlign: "right",
    direction: "ltr",
    fontFamily: "roxbor",
    "& .container_txt": {
      "& p": {
        fontSize: "28px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "20px",
        },
      },
    },
  },
  main_en: {
    textAlign: "left",
    direction: "rtl",
    fontFamily: "Belleza",
    "& .container_txt": {
      "& p": {
        fontSize: "28px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "20px",
        },
      },
    },
  },
}));
