import React, { useEffect, useState } from "react";
import { GetLang } from "../../../Actions/UserRedux";

import Grid from "@material-ui/core/Grid";

import Container1 from "../../../Components/Simple/Container1";

import ScrollToThis from "../../../Components/Simple/ScrollToThis";

import { DateParser } from "../../../Functions/DateFunctions";
import {
  getOneBlog,
  getAllRelatedCardBlogs,
} from "../../../services/BlogServices";
import Spinner from "../../../Components/Simple/Spinner";
import YouTubeVideo from "../../../Components/Simple/YouTubeVideo/YouTubeVideo";
import BlogCard from "../../../Components/Cards/BlogCard/BlogCard";

import { useStyles } from "./BlogStyles";
import { useHistory } from "react-router-dom";
import { Avatar } from "@material-ui/core";

function Blog(props) {
  const cs = useStyles();
  let lang = GetLang() === "EN";
  const [article, setArticle] = useState(null);
  const [blogs, setBlogs] = useState(null);
  const histo = useHistory();

  useEffect(async () => {
    setArticle(await getOneBlog(props.match.params.id));
  }, [props.match.params.id]);

  useEffect(() => {
    if (article)
      if ((article.en == false && lang) || (article.ar == false && !lang)) {
        histo.push("/blogs");
      }
  }, [article, lang]);

  useEffect(async () => {
    if (article) {
      const blgs = await getAllRelatedCardBlogs(article.categs, article.id);
      setBlogs(blgs);
    }
  }, [article]);

  return article ? (
    <>
      <div className={cs.main}>
        <ScrollToThis />
        <Container1>
          <Grid container spacing={2} direction={lang ? "row" : "row-reverse"}>
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <div className={cs.article + ` ${lang ? "" : cs.article_ar} `}>
                <div className={cs.head + " head"}>
                  <div className="date_and_category">
                    <h3 className="author_part">
                      <Avatar className="avatar" src={article.author_img} />
                      {lang
                        ? `Author : ${article.author_en}`
                        : `الكاتب(ة) : ${article.author_ar} `}
                    </h3>
                    <h3 className="date_part">{DateParser(article.date)}</h3>
                  </div>
                </div>

                <div className={cs.body + " body"}>
                  {(lang ? article.content_en : article.content_ar).map(
                    ({ type, value }, key) => {
                      if (type === "Title_L") {
                        return <h1 key={key}>{value}</h1>;
                      }

                      if (type === "Title_M") {
                        return <h2 key={key}>{value}</h2>;
                      }

                      if (type === "Parag") {
                        return <p key={key}>{value}</p>;
                      }

                      if (type === "Image") {
                        return <img key={key} src={value} />;
                      }

                      if (type === "VideoUrl") {
                        return <YouTubeVideo key={key} src={value} />;
                      }
                    }
                  )}
                </div>
                <div className={cs.footer + " footer"}>
                  <h3>المصادر :</h3>
                  {(lang ? article.references_en : article.references_ar)
                    .split("-")
                    .map((reference, key) => {
                      return <p key={key}>{reference}</p>;
                    })}
                </div>
              </div>
            </Grid>
            <Grid item lg={4} md={12} sm={12} xs={12}>
              <br />
              <Grid container spacing={3}>
                {blogs ? (
                  blogs
                    .filter((blg) => {
                      return (lang && blg.en) || (!lang && blg.ar);
                    })
                    .map((card, key) => {
                      return (
                        <Grid key={key} item lg={12} md={6} sm={12} xs={12}>
                          <BlogCard blog={card} isVert={true} />
                        </Grid>
                      );
                    })
                ) : (
                  <Spinner />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Container1>
      </div>
    </>
  ) : (
    <Spinner />
  );
}

export default Blog;
