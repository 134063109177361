import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    margin: "auto",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",

    border: "none",
    height: "450px",
    width: "90%",
    borderRadius: "0px",
    backgroundColor: "rgba(0,0,0,0)",
    borderBottom: "solid 2px black",

    [theme.breakpoints.down("sm")]: {
      border: "none",
      height: "500px",
      borderRadius: "0px",
      backgroundColor: "rgba(0,0,0,0)",
      borderBottom: "solid 2px black",
    },

    "& .title": {
      width: "100%",
      height: "10%",
      padding: "0px 10px",
      cursor: "pointer",

      display: "flex",
      alignItems: "flex-end",
      justifyContent: "flex-start",

      lineHeight: "1.3",
      [theme.breakpoints.down("sm")]: {
        height: "10%",
      },

      "& p": {
        color: "#0F0E0C",
        fontWeight: "700",
        fontSize: "22px",
        width: "100%",
        padding: "0px",
        margin: "0px",
        textAlign: "right",
        fontFamily: "Hudhud",
      },
    },

    "& .container_img": {
      width: "100%",
      height: "74%",
      cursor: "pointer",

      [theme.breakpoints.down("sm")]: {
        height: "74%",
      },

      "& .img": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        borderRadius: "10px",
      },
    },

    "& .details": {
      width: "100%",
      height: "16%",

      display: "flex",
      alignItems: "center",
      justifyContent: "space-evenly",
      flexDirection: "column",
      padding: "0px 10px 10px 10px",

      "& .tag_and_date": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },

      "& .categ_and_likes": {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      },

      "& .date": {
        fontFamily: "Tajawal",
        fontSize: "16px",
        color: "#FB6459",
        fontWeight: "500",
      },

      "& .tag": {
        fontFamily: "Tajawal",
        fontSize: "16px",
        color: "#FB6459",
        fontWeight: "500",
        cursor: "pointer",
        // transform: "translateY(4px)",

        "& a": {
          textDecoration: "none",
          color: "#FB6459",
        },
      },

      "& .categ": {
        fontFamily: "Hudhud",
        fontSize: "20px",
        fontWeight: "500",
        backgroundColor: "#000",
        borderRadius: "5px",
        lineHeight: "1.2",
        padding: "8px 4px 4px 4px",
        cursor: "pointer",
        // transform: "translateY(4px)",

        "& a": {
          textDecoration: "none",
          color: "white",
        },
      },

      "& .likes": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& .like": {
          borderRadius: "500px",
          cursor: "pointer",

          height: "30px",
          width: "30px",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",

          "& img": {
            height: "100%",
            width: "100%",
          },
        },

        "& .liked": {
          color: "#FB6459",
        },

        "& .value": {
          fontFamily: "Tajawal",
          fontSize: "22px",
          padding: "0px 5px",
          fontWeight: "500",
          color: "black",
        },
      },
    },
  },

  main_ar: {
    textAlign: "right",
    direction: "ltr",
    fontFamily: "roxbor",
    "& .container_txt": {
      "& p": {
        fontSize: "28px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "20px",
        },
      },
    },
  },
  main_en: {
    textAlign: "left",
    direction: "rtl",
    fontFamily: "Belleza",
    "& .container_txt": {
      "& p": {
        fontSize: "28px",
        [theme.breakpoints.down("xs")]: {
          fontSize: "20px",
        },
      },
    },
  },
}));
