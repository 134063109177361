import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import { GetLang } from "../../../Actions/UserRedux";

const useStyles = makeStyles((theme) => ({
  S_sign_btn: {
    color: theme.palette.black.main,
    borderRadius: "5px !important",
    border: "solid 1px black",
    fontSize: "18px !important",
    fontFamily: "Abhaya",
    textTransform: "none",
    borderRadius: "0px",
    position: "relative",
    zIndex: "1",

    //width: "145px",
    padding: "0px 40px",
    height: "40px",
    transition: "all 0.3s",
    "&:hover": {
      transform: "scale(1.1)",
    },
  },
  ar: {
    fontFamily: "AraHamah",
  },

  white: {
    border: `solid 1px ${theme.palette.black.text}`,
    color: theme.palette.black.text,
    "&:hover": {
      border: `solid 1px ${theme.palette.black.text}`,
      transform: "scale(1.1)",
    },
  },
}));

//Sign up
const OutlinedButton = (props) => {
  const { S_sign_btn, ar, white } = useStyles();
  const { style, color = "black" } = props;
  let lang = GetLang() === "EN";

  return (
    <Button
      onClick={props.onClick}
      variant="outlined"
      style={{ ...style }}
      className={
        S_sign_btn + ` ${!lang && ar}` + ` ${color === "white" && white}`
      }
    >
      {props.children}
    </Button>
  );
};

export default OutlinedButton;
