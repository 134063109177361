import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
  main: {
    // backgroundColor: "#0F0E0C",
    padding: "80px 0px",
    direction: "ltr",
    textAlign: "center",
    "& h1": {
      display: "inline",
      color: "black",
      fontSize: "60px",
      borderBottom: "solid 5px black",
      padding: "10px 30px",
      margin: "0px",

      [theme.breakpoints.down("sm")]: {
        display: "block",
        fontSize: "35px",
        marginBottom: "30px",
      },
    },

    "& a": {
      textDecoration: "none",
    },

    // **************************** AR - EN ********************************

    "& .ar": {
      "& h1": {
        fontFamily: "Hudhud",
        fontWeight: "500",
      },
    },

    "& .en": {
      "& h1": {
        fontFamily: "roxbor",
      },
    },
  },
}));
