import React from "react";
import { useStyles } from "./GallerysStyles";
import { GetLang } from "../../Actions/UserRedux";
import { Grid } from "@material-ui/core";
import ScrollToThis from "../../Components/Simple/ScrollToThis";
import Container1 from "../../Components/Simple/Container1";
import GalleryCard from "../../Components/Cards/GalleryCards/GalleryCard";

import { galleryShow } from "../../services/GalleryServices";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Spinner from "../../Components/Simple/Spinner";

function Gallerys() {
  const cs = useStyles();
  let lang = GetLang() === "EN";

  const [gallerys] = useCollectionData(galleryShow, { idField: "id" });

  return (
    <div className={cs.main}>
      <ScrollToThis />
      <Container1>
        <div className={lang ? "en" : " ar"}>
          <h1>{lang ? "Our Gallery" : "المعرض"}</h1>

          <br />
          <br />
          <br />
          <br />

          <Grid container spacing={0}>
            {gallerys ? (
              gallerys
                .filter((gh) => {
                  return (lang && gh.en) || (!lang && gh.ar);
                })
                .map((item, key) => {
                  return (
                    <Grid key={key} item lg={4} md={4} sm={6} xs={12}>
                      <div>
                        <GalleryCard gal={item} />
                        <br />
                        <br />
                        <br />
                      </div>
                    </Grid>
                  );
                })
            ) : (
              <Spinner />
            )}
          </Grid>
        </div>
      </Container1>
    </div>
  );
}

export default Gallerys;
