import React, { useState, useEffect } from "react";
import { useStyles } from "./ForymStyles";
import { GetLang } from "../../Actions/UserRedux";
import ScrollToThis from "../../Components/Simple/ScrollToThis";
import Container1 from "../../Components/Simple/Container1";
import { useHistory } from "react-router";
import { memoryShowCategs } from "../../services/ForMemServices";
import { useCollectionData } from "react-firebase-hooks/firestore";
import Spinner from "../../Components/Simple/Spinner";
import AddCircleIcon from "@material-ui/icons/AddCircle";

function ForumMemory() {
  const cs = useStyles();
  let lang = GetLang() === "EN";
  const history = useHistory();

  const [categories = []] = useCollectionData(memoryShowCategs, {
    idField: "id",
  });
  const [grouped_categs, set_grouped_categs] = useState([]);

  useEffect(() => {
    let new_array = [];
    let grou_of2 = [];
    categories.forEach((categ, key) => {
      grou_of2.push(categ);
      if (key % 2 === 1) {
        new_array.push([...grou_of2]);
        grou_of2 = [];
      }
    });
    set_grouped_categs([...new_array]);
  }, [categories]);

  const GoToMemosPage = (type = "") => {
    type = type.split(" ").join("_");
    history.push("/forum_memory/" + type);
  };

  return (
    <div className={cs.main}>
      <ScrollToThis />
      <Container1>
        <div className={lang ? "en" : " ar"}>
          <h1>{lang ? "Forum Memory" : "ذاكرة المنتدى"}</h1>

          <div className={cs.cards_for_selections}>
            {grouped_categs.map((categs, key) => {
              let sizeA = "h50";
              let sizeB = "h50";
              if (key === 0) {
                sizeA = "h25";
                sizeB = "h75";
              } else if (key === 3) {
                sizeA = "h75";
                sizeB = "h25";
              }
              return (
                <div key={key} className="group_2">
                  <div
                    style={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${categs[1].img})`,
                    }}
                    onClick={() => {
                      GoToMemosPage(categs[1].title_en);
                    }}
                    className={`card ${sizeA} ${lang ? "card_en" : "card_ar"}`}
                  >
                    {lang ? categs[1].title_en : categs[1].title_ar}
                    <AddCircleIcon className="icon" />
                  </div>
                  <div
                    style={{
                      backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${categs[0].img})`,
                    }}
                    onClick={() => {
                      GoToMemosPage(categs[0].title_en);
                    }}
                    className={`card ${sizeB} ${lang ? "card_en" : "card_ar"}`}
                  >
                    {lang ? categs[0].title_en : categs[0].title_ar}
                    <AddCircleIcon className="icon" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Container1>
    </div>
  );
}

export default ForumMemory;

const Card = (props) => {
  const { title_en, title_ar, img, id } = props.card;
  const cs = useStyles();
  const history = useHistory();
  let lang = GetLang() === "EN";

  const gotoMemo = () => {
    history.push("/memory/" + id);
  };

  return (
    <div className={cs.card} onClick={gotoMemo}>
      <img src={img} style={{ width: "100%" }} />
      <div className="text">
        <h3 className={!lang ? "ar_h3" : ""}>{lang ? title_en : title_ar}</h3>
      </div>
    </div>
  );
};
