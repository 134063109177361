import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    "& .horizantal_blog": {
      display: "block",
    },
    "& .card_blog": {
      display: "none",
    },
    // [theme.breakpoints.down("sm")]: {
    //   "& .horizantal_blog": {
    //     display: "none",
    //   },
    //   "& .card_blog": {
    //     display: "block",
    //   },
    // },
  },

  S_container: {
    position: "relative",
    padding: "70px 0px 0px 0px",
    [theme.breakpoints.down("md")]: {
      padding: "30px 0px 30px 0px",
    },
    fontFamily: "roxbor",
    "& .ar_h2": {
      fontFamily: "Hudhud",
      textAlign: "right",
      fontSize: "60px",
      margin: "0px 50px 0px 0px",

      [theme.breakpoints.down("sm")]: {
        margin: "0px 0px 20px 0px",
        textAlign: "center",
      },
    },
  },
  S_image: {
    backgroundSize: "cover",
    backgroundPosition: "center center",
    height: "350px",
    width: "480px",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "360px",
      height: "300px",
    },
  },
  S_Left: {
    "& .aos-init": {
      height: "100%",
    },
  },
  S_Right: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  S_Text: {
    padding: "0px 20px 0px 20px",
    color: theme.palette.black.main,
    fontFamily: "roxbor",
    textAlign: "left",
    height: "100%",

    "& .ar_h2": {
      fontFamily: "Hudhud",
      fontSize: "35px",
      textAlign: "right",
      margin: "20px 0px 0px 0px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
      },
    },

    "& p": {
      fontFamily: "Aileron",
      fontSize: "28px",
      margin: "20px 0px",
      lineHeight: 1.7,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": "2",
      "-webkit-box-orient": "vertical",

      [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
        lineHeight: 1.5,
      },
    },

    "& .ar_p": {
      fontFamily: "Jazera",
      fontSize: "24px",
      textAlign: "right",
      margin: "0px 0px 0px 0px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
      },
    },

    "& .author": {
      fontFamily: "Hudhud",
      fontSize: "26px",
      margin: "10px 0px 0px 0px",
      color: "#13613A",
      [theme.breakpoints.down("xs")]: {
        fontSize: "24px",
      },
    },

    "& .categorys": {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-end",
      margin: "10px 0px 0px 0px",

      "& .categ": {
        color: "#01161E",
        backgroundColor: "#FB6459",
        padding: "0px 10px",
        fontSize: "22px",
        fontFamily: "Hudhud",
        borderRadius: "10px",

        [theme.breakpoints.down("xs")]: {
          fontSize: "20px",
        },
      },
    },

    "& .moreBtn": {
      margin: "30px 0px 0px 0px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
  S_h2: {
    fontSize: "40px",
    fontWeight: "200",
    margin: "0px 0px -50px 50px",
    position: "relative",
    zIndex: "1",
    "& *": {
      textDecoration: "none",
      color: "black",
    },
  },
}));
