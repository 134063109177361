import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  S_slide: {
    padding: "50px 0px 30px 0px",
    color: theme.palette.black.main,

    "& .ar_h2": {
      fontFamily: "Hudhud",
      textAlign: "right",
      fontSize: "60px",
      margin: "0px 0px -18px 0px",

      [theme.breakpoints.down("sm")]: {
        margin: "0px 0px 20px 0px",
        textAlign: "center",
      },
    },
  },

  S_card: {
    height: "100%",
    padding: "30px 0px",
    borderBottom: "solid 2px black",

    [theme.breakpoints.down("sm")]: {
      margin: "0px 40px",
    },
  },
  S_img: {
    height: "180px",
    backgroundSize: "cover",
    backgroundPosition: "center center",
  },

  S_arh2: {
    fontFamily: "Hudhud",
    margin: "40px 0px",
    textAlign: "center",
  },
  S_h2: {
    fontSize: "40px",
    fontWeight: "200",
    margin: "0px 0px 20px 50px",
    position: "relative",
    zIndex: "1",

    "& *": {
      textDecoration: "none",
      color: "black",
    },

    [theme.breakpoints.down("sm")]: {
      margin: "0px 0px 20px 0px",
      textAlign: "center",
    },
  },

  S_text: {
    "& h3": {
      fontFamily: "roxbor",
      fontSize: "32px",
      fontWeight: "300",
    },

    "& .ar_h3": {
      fontFamily: "Hudhud",
      textAlign: "right",
      margin: "10px 0px 0px 0px",
    },
    "& p": {
      margin: "0px 0px 0px 0px",
      fontFamily: "Aileron",
      fontSize: "21px",
    },

    "& .ar_p": {
      fontFamily: "Jazera",
      lineHeight: "1.4",
      textAlign: "right",
    },
  },
}));
