import React from 'react'
import Typewriter from "typewriter-effect";


function TypingText(props) {
    return (
        <Typewriter
              options={{
                delay: 60,
              }}
              onInit={(typewriter) => {
                typewriter
                  .typeString(
                    props.children
                  )
                  .start(false);
              }}
            />
    )
}

export default TypingText
