import React, { useEffect, useState } from "react";

import { makeStyles } from "@material-ui/core/styles";
//import Container1 from "../../Components/Simple/Container1";
import { GetLang } from "../../Actions/UserRedux";

import ScrollToThis from "../../Components/Simple/ScrollToThis";
import { useHistory } from "react-router-dom";

import { getOneMemo } from "../../services/ForMemServices";
import Spinner from "../../Components/Simple/Spinner";
import { DateParser } from "../../Functions/DateFunctions";

const useStyles = makeStyles((theme) => ({
  Gallery: {
    // backgroundColor: theme.palette.black.main,
    width: "100%",

    [theme.breakpoints.down("md")]: {},

    color: "black",

    "& .txt": {
      padding: "50px 100px",
      [theme.breakpoints.down("md")]: {
        padding: "50px 40px 50px 40px",
      },
    },

    "& .content-memo": {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      "& p": {
        width: "64%",
      },
      "& .img-cont": {
        width: "35%",
        "& img": {
          width: "100%",
        },
      },

      [theme.breakpoints.down("sm")]: {
        flexDirection: "column !important",
        "& p": {
          width: "100%",
          textAlign: "center",
        },
        "& .img-cont": {
          width: "80%",
        },
      },
    },

    "& .en-orientation": {
      flexDirection: "row",
    },

    "& .ar-orientation": {
      flexDirection: "row-reverse",
    },

    "& .ar": {
      textAlign: "right",
    },

    "& .en": {
      textAlign: "left",
    },

    "& h1.ar,h2.ar": {
      fontFamily: "Hudhud",
      fontWeight: "100",
      margin: "0px",
      padding: "0px",
    },

    "& h1.en,h2.en": {
      fontFamily: "roxbor",
      fontWeight: "100",
      margin: "0px 0px 10px 0px",
      padding: "0px",
    },

    "& h1": {
      fontSize: "50px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        textAlign: "center !important",
      },
    },

    "& h2": {
      fontSize: "40px",
      [theme.breakpoints.down("xs")]: {
        fontSize: "30px",
        textAlign: "center !important",
      },
    },

    "& hr": {
      backgroundColor: theme.palette.black.text,
      width: "100%",
      height: "2px",
      margin: "0px 0px 50px 0px",
      [theme.breakpoints.down("sm")]: {
        margin: "0px 0px 30px 0px",
      },
    },

    "& p": {
      fontFamily: "HSDreamLight",
      fontSize: "30px",

      [theme.breakpoints.down("xs")]: {
        fontSize: "20px",
        fontWeight: "300",
        lineHeight: "1.5",
        textAlign: "center !important",
      },
    },

    "& p.ar": {
      fontFamily: "Jazera",
      fontWeight: "500",
      paddingLeft: "20px",
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "0px",
      },
    },

    "& p.en": {
      paddingRight: "20px",
      [theme.breakpoints.down("xs")]: {
        paddingRight: "0px",
      },
    },

    "& .img": {
      display: "flex",
      flexDirection: "row-reverse",
      width: "100%",
      //paddingBottom:"100px",

      [theme.breakpoints.down("xs")]: {
        justifyContent: "center",
        alignItems: "center",
      },

      "& .bgImg": {
        height: "650px",
        width: "45%",
        backgroundPosition: "70% center",
        backgroundSize: "cover",

        [theme.breakpoints.down("sm")]: {
          display: "none",
        },
      },

      "& .title_and_img": {
        padding: "80px 60px",
        width: "100%",
        position: "relative",
        [theme.breakpoints.down("sm")]: {
          padding: "20px 40px",
        },

        "& .min_img": {
          height: "400px",
          width: "550px",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          marginRight: "-150px",
          [theme.breakpoints.down("sm")]: {
            marginRight: "0px",
            width: "100%",
            margin: "auto",
          },
        },
        "& .min_img_ar": {
          height: "400px",
          width: "550px",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          position: "absolute",
          right: "0px",
          [theme.breakpoints.down("sm")]: {
            marginRight: "0px",
            marginLeft: "0px",
            width: "100%",
            margin: "auto",
            position: "relative",
          },
        },
      },
    },

    "& .img_left": {
      flexDirection: "row",
      "& .title_and_img": {
        margin: "0px 0px 0px -150px",
        [theme.breakpoints.down("sm")]: {
          margin: "0px",
          textAlign: "center",
          width: "100%",
        },
        "& h1": {
          marginLeft: "100px",
          [theme.breakpoints.down("sm")]: {
            margin: "0px",
            textAlign: "center",
            width: "100%",
          },
        },
      },
    },
  },
}));

function Memory(props) {
  const cs = useStyles();
  let lang = GetLang() === "EN";
  const [gall, setMemory] = useState(null);
  const histo = useHistory();

  // if not connected with language so back
  useEffect(() => {
    if (gall) {
      if ((gall.en == false && lang) || (gall.ar == false && !lang)) {
        histo.push("/memory");
      }
    }
  }, [gall, lang]);

  useEffect(async () => {
    setMemory(await getOneMemo(props.match.params.id));
  }, []);

  return gall ? (
    <div className={cs.Gallery}>
      <ScrollToThis />
      <div className="txt">
        <h1 className={lang ? "en" : "ar"}>
          {lang ? gall.title_en : gall.title_ar}
        </h1>
        <hr />
        <div
          className={
            "content-memo " + (lang ? "en-orientation" : "ar-orientation")
          }
        >
          <p className={lang ? "en" : "ar"}>
            {lang ? gall.description_en : gall.description_ar}
          </p>
          <div className="img-cont">
            <img src={gall.img} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner />
  );
}

export default Memory;
