import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    padding: "50px 40px 100px 40px",
    width: "100%",
    textAlign: "center",

    boxSizing: "border-box",
    fontFamily: "roxbor",
    color: "white",

    "& .ar": {
      "& h1": {
        fontFamily: "Hudhud",
        fontWeight: "500",
        color: "black",
      },
    },

    "& .en": {
      "& h1": {
        fontFamily: "roxbor",
        color: "black",
      },
    },

    "& h1": {
      display: "inline",
      color: "white",
      fontSize: "60px",
      borderBottom: "solid 5px black",
      padding: "10px 30px",
      margin: "0px",

      [theme.breakpoints.down("sm")]: {
        display: "block",
        fontSize: "35px",
        marginBottom: "30px",
      },
    },
  },
}));
