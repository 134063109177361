import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  //***************************** Navbar Container *******************************

  S_nav: {
    width: "100%",
    height: "80px",
    position: "fixed",
    zIndex: "100",

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",

    margin: " 30px auto",

    [theme.breakpoints.down("lg")]: {
      padding: "0px 5%",
    },

    [theme.breakpoints.down("md")]: {
      padding: "0px 1%",
      top: "0px",
      transition: "width 0.3s",
    },

    [theme.breakpoints.down("sm")]: {
      width: "80px",
      height: "80px",
      padding: "0px",
      margin: "0px auto",
    },

    [theme.breakpoints.down("xs")]: {},
  },
  open_navbar: {
    [theme.breakpoints.down("sm")]: {
      width: "300px",
      height: "auto",
    },
  },
  ar_navbar: {
    [theme.breakpoints.down("sm")]: {
      right: "0px",
    },
  },
  en_navbar: {
    [theme.breakpoints.down("sm")]: {
      left: "0px",
    },
  },

  shrink: {
    backgroundColor: "rgba(0,0,0,0.6)",
    backdropFilter: "blur(10px)",
    boxShadow: "0px 5px 30px #00000091",
    margin: "0px",
    "& *": {
      borderBottom: "solid 0px rgba(255,255,255,0.2) !important",
    },

    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#00000000",
      backdropFilter: "blur(0px)",
      boxShadow: "0px 5px 30px #00000000",
      "& .iconbtnMenu": {
        backgroundColor: "#00000000",
        boxShadow: "0px 0px 10px 2px #00000091",
      },
    },
  },

  relative: {
    position: "relative",
  },

  //***************************** Routes Container ******************************
  S_routes: {
    height: "100%",
    width: "75%",
    display: "flex",
    justifyContent: "center",
    // justifyContent: "flex-start",
    alignItems: "center",
    marginRight: "70px",
    borderBottom: "solid 0.5px rgba(255,255,255,0.2)",
    position: "relative",

    "& a": {
      color: "white",
      textDecoration: "none",
    },

    [theme.breakpoints.down("md")]: {
      marginRight: "0px",
      width: "80%",
    },

    //***************************** Routes Elements ******************************
    "& div": {
      transform: "scale(1.01)",
      height: "100%",
      paddingTop: "26px",
      fontFamily: "Belleza",
      fontSize: "18px",
      fontWeight: "100",
      color: "white",
      margin: "0px 23px",
      display: "flex",
      justifyContent: "center",
      position: "relative",

      [theme.breakpoints.down("md")]: {
        margin: "0px 20px",
      },

      "&:before": {
        content: '""',
        position: "absolute",
        bottom: "0",

        display: "block",
        background: "rgba(255,255,255,0.9)",
        height: "1.8px",
        width: "0px",
        transition: "0.3s all",
      },

      "&:hover::before": {
        borderBottom: "solid 1px rgba(255,255,255,0.9)",
        width: "100%",
      },
      "&:hover": {
        cursor: "pointer",
      },
    },

    "& .ar": {
      fontFamily: "Tajawal",
    },

    //***************************** Routes EN AR ******************************
    "& .lang": {
      margin: "5px",
    },

    "& .push": {
      marginRight: "auto",
    },
  },

  // **************************** responsive ********************************

  s_routes_resp: {
    height: "656px",
    width: "0px",
    overflow: "hidden",
    backgroundColor: "black",
    color: "white",
    padding: "0px",
    fontFamily: "Belleza", //

    "& .container": {
      borderLeft: "solid 0.5px rgba(255,255,255,0.2)", //
      marginTop: "60px",

      "& a": {
        color: "white",
        textDecoration: "none",
      },

      "& .space": {
        width: "10px",
      },
      "& .lang": {
        borderBottom: "solid 1px rgba(0,0,0,0)",
        "&:hover": {
          borderBottom: "solid 1px white",
        },
      },
    },

    "& .button": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    },

    "& h3": {
      paddingLeft: "10px",
      margin: "20px 0px",
      padding: "10px",
      transition: "all 0.3s",
      position: "relative",
      display: "flex",
      alignItems: "center",

      "&:before": {
        content: '""',
        position: "absolute",
        left: "-2px",
        display: "block",
        background: "rgba(255,255,255,0.9)",
        height: "1.8px",
        width: "0px",
        transition: "0.3s all",
      },

      "&:hover::before": {
        borderLeft: "solid 4px rgba(255,255,255,0.9)",
        height: "100%",
      },
      "&:hover": {
        //borderLeft: "solid 2px rgba(255,255,255,0.9)",
        paddingLeft: "20px",
        cursor: "pointer",
      },
    },
  },

  routes_resp_R: {
    "& .container": {
      fontFamily: "Tajawal",
      borderLeft: "none", //
      borderRight: "solid 0.5px rgba(255,255,255,0.2)",

      "& h3": {
        justifyContent: "flex-start",
        paddingLeft: "0px",
        paddingRight: "10px",

        "& a": {
          color: "white",
          textDecoration: "none",
        },

        "&:before": {
          left: "auto",
          right: "-2px",
          height: "1.8px",
          width: "0px",
        },

        "&:hover::before": {
          borderLeft: "none",
          borderRight: "solid 4px rgba(255,255,255,0.9)",
          height: "100%",
        },
        "&:hover": {
          paddingLeft: "0px",
          paddingRight: "20px",
        },
      },
    },
  },

  s_routes_resp_open: {
    width: "300px",
    padding: "20px",
    height: "100vh",
  },

  menu_icon: {
    position: "absolute !important",
    backgroundColor: "transparent",
    color: "white !important",
    top: "10px",
    padding: "0px !important",
    transition: "all 0s",

    "& img": {
      height: "50px",
      width: "50px",
      borderRadius: "500px",
    },

    "& .close_icon": {
      transform: "scale(1.3) translateX(-5px) translateY(10px)",
    },

    "&:hover": {
      backgroundColor: "transparent",
    },
  },

  menu_icon_R: {
    right: "10px",
  },

  menu_icon_L: {
    left: "10px",
  },

  flexStart: {
    justifyContent: "flex-start",
  },
}));
