import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import { useEffect, useState } from "react";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

const outerTheme = createMuiTheme({
  palette: {
    primary: {
      main: "#01773B",
    },
  },
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <h1 elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbars({ show, children }) {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <ThemeProvider theme={outerTheme}>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#01773B",
              borderRadius: "5px",
            }}
          >
            <h5
              style={{
                margin: "0px",
                padding: "10px 50px",
                fontSize: "18px",
                transform: "translateY(-4px)",
              }}
            >
              <DoneAllIcon style={{ transform: "translateY(5px)" }} />
              {children}
            </h5>
          </div>
        </Alert>
      </Snackbar>
    </ThemeProvider>
  );
}
