import axios from "axios";

const { REACT_APP_API_HOST } = process.env;
const api = REACT_APP_API_HOST;

export const connect = async () => {
  try {
    const res = await axios.post(`${api}/api/users/connected`);
    //console.log(res);
    return 0;
  } catch (error) {
    //console.log(error);
  }
};

export const disconnect = async () => {
  try {
    const res = await axios.post(`${api}/api/users/disconnected`);
    //console.log(res);
  } catch (error) {
    //console.log(error);
  }
};
