import React, { useState, useEffect } from "react";

import Grid from "@material-ui/core/Grid";
import withWidth from "@material-ui/core/withWidth";
import { GetLang } from "../../../Actions/UserRedux";
import Spinner from "../../../Components/Simple/Spinner";
import { useHistory } from "react-router";

import Container1 from "../../../Components/Simple/Container1";
import Swipper2 from "../../../Components/Complex/Swipper2";

// import MemoryCard from "../../Components/Cards/MemoryCard/MemoryCard";
import MemoryCard from "../../../Components/Cards/MemoryCard_v2/MemoryCard";

import { TableToNb } from "../../../Functions/functions";
import { FadeDown } from "../../../Components/Animations/Fade";

import { ZoomIn } from "../../../Components/Animations/Zoom";
import { NavLink } from "react-router-dom";

import { useStyles } from "./styes";

const ForumMemory = (props) => {
  const { width, formem } = props;
  const [Memorys, SetMemorys] = useState([...formem]);
  let lang = GetLang() === "EN";

  useEffect(() => {
    let tab = formem
      .filter((memo) => {
        console.log(memo);
        return (lang && memo.en) || (!lang && memo.ar);
      })
      .filter((memo) => (memo.inMobile && width === "xs") || width !== "xs");

    if (width === "lg" || width === "xl" || width === "md")
      SetMemorys([...TableToNb(tab, 3)]);
    else if (width === "sm") SetMemorys([...TableToNb(tab, 2)]);
    else if (width === "xs") SetMemorys([...TableToNb(tab, 1)]);
  }, [width, formem, lang]);

  const history = useHistory();

  const gotopage = () => {
    history.push("/forum_memory");
  };

  return formem.length ? (
    <Swipper2
      Component={Memories}
      gotopage={gotopage}
      elements={Memorys}
    ></Swipper2>
  ) : (
    <Spinner />
  );
};

const Memories = (props) => {
  const { elem, width } = props;
  let lang = GetLang() === "EN";

  const css = useStyles();
  return (
    <div className={css.S_slide}>
      <Container1>
        <h2 className={css.S_h2 + (!lang ? " ar_h2" : "")}>
          <FadeDown delay={0} duration={1000}>
            <NavLink to="/forum_memory">
              {lang ? (
                <>
                  Forum <br /> Memory
                </>
              ) : (
                <>ذاكرة المنتدى</>
              )}
            </NavLink>
          </FadeDown>
        </h2>

        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          style={{ overflow: "hidden" }}
        >
          <Grid container spacing={4} direction={!lang ? "row-reverse" : "row"}>
            {elem?.map((memo, key) => {
              return (
                <Grid item xl={4} lg={4} md={4} sm={6} xs={12} key={key}>
                  <ZoomIn>
                    <MemoryCard memory={memo} />
                  </ZoomIn>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Container1>
    </div>
  );
};

// const Card = (props) => {
//   const { title_en, title_ar, img, description_ar, description_en } =
//     props.card;
//   const { S_card, S_img, S_text } = useStyles();
//   let lang = GetLang() === "EN";

//   return (
//     <div className={S_card}>
//       {/* <div className={S_img} style={{ backgroundImage: `url(${img})` }} /> */}
//       <img src={img} style={{ width: "100%" }} />
//       <div className={S_text}>
//         <h3 className={!lang ? "ar_h3" : ""}>{lang ? title_en : title_ar}</h3>
//         <p className={!lang ? "ar_p" : ""}>
//           {lang ? description_en : description_ar}
//         </p>
//       </div>
//     </div>
//   );
// };

export default withWidth()(ForumMemory);
