import React, { useEffect, useState } from "react";
import OurEvents from "./OurEvents/OurEvents";
import ForumMemory from "./ForumMemory/ForumMemory";
import OurGallery from "./OurGallery/OurGallery";
import WhyPalestineForum from "./whypalestineforum/WhyPalestineForum";
import OurBlogs from "./OurBlogs/OurBlogs";

import { galleryHome } from "../../services/GalleryServices";
import { getAllCardBlogsHome } from "../../services/BlogServices";
import { eventHome } from "../../services/EventsServices";
import { memoryHome } from "../../services/ForMemServices";
import { useCollectionData } from "react-firebase-hooks/firestore";

const Home = () => {
  const [gallery] = useCollectionData(galleryHome, { idField: "id" });
  const [events] = useCollectionData(eventHome, { idField: "id" });
  const [formem] = useCollectionData(memoryHome, { idField: "id" });
  const [blogs, setBlogs] = useState(null);

  useEffect(async () => {
    const blgs = await getAllCardBlogsHome();
    setBlogs(blgs);
  }, []);

  return (
    <div>
      <OurBlogs blogs={blogs ? blogs : []} />
      <ForumMemory formem={formem ? formem : []} />
      <OurEvents events={events ? events : []} />
      <OurGallery gallery={gallery ? gallery : []} />
      <WhyPalestineForum />
    </div>
  );
};

export default Home;
