import React, { useEffect } from "react";

import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import NavBar from "./Layouts/Navbar/NavBar";
import Home from "./views/Home/Home";
import AllBlogs from "./views/Blogs/AllBlogs";
import CategBlogs from "./views/Blogs/CategBlogs";
import Blog from "./views/Blogs/OneBlog/Blog";
import Head from "./Layouts/header/Head";
import Gallerys from "./views/Gallery/Gallerys";
import Gallery from "./views/Gallery/Gallery";
import Memory from "./views/Forum/OneMemory";
import ForumMemory from "./views/Forum/ForumMemory";
import ForumMemory2 from "./views/Forum/ForumMemory2";
import ForumMemory3 from "./views/Forum/ForumMemory3";
import ContactUs from "./views/ContactUs/ContactUs";
import ReactGA from "react-ga";
import { useDispatch } from "react-redux";
import { SetScreenSize } from "./Actions/UserRedux";
import CustomToast from "./Components/Simple/Toast/CustomToaster";

import {
  Switch,
  Route,
  Redirect,
  useLocation,
  BrowserRouter as Router,
} from "react-router-dom";
import Footer from "./Layouts/Footer";
import AboutUs from "./views/About/AboutUs";
import AllEvents from "./views/events/AllEvents";
import { connect, disconnect } from "./services/Users";

const outerTheme = createMuiTheme({
  palette: {
    black: {
      main: "#0F0E0C",
      text: "#ffffff",
    },
    white: {
      main: "#f5f5ef",
    },
    primary: {
      main: "#f5f5ef",
    },
    green: {
      main: "#13613A",
    },
  },
});

function App(props) {
  const { width } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    ReactGA.initialize("UA-214528147-1");
  }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname + location.search);
  }, [location]);

  useEffect(async () => {
    await connect();

    window.addEventListener("unload", async () => {
      await disconnect();
    });
    return () => {
      window.removeEventListener("unload");
    };
  }, []);

  useEffect(() => {
    dispatch(SetScreenSize(width));
  }, [width]);

  return (
    <div className="App">
      <ThemeProvider theme={outerTheme}>
        <CustomToast />
        <NavBar />
        <Head />
        <Switch>
          <Route path="/home" component={Home} />
          <Route exact path="/blogs" component={CategBlogs} />
          <Route exact path="/blogs/:type" component={AllBlogs} />
          <Route path="/blog/:id" component={Blog} />
          <Route path="/events" component={AllEvents} />
          <Route exact path="/gallery" component={Gallerys} />
          <Route exact path="/gallery/:id" component={Gallery} />
          <Route path="/about" component={AboutUs} />
          <Route exact path="/forum_memory" component={ForumMemory} />
          <Route exact path="/forum_memory/:type" component={ForumMemory2} />
          <Route exact path="/forum_memorys/:type" component={ForumMemory3} />
          <Route path="/memory/:id" component={Memory} />
          <Route path="/contact" component={ContactUs} />

          <Redirect from="/" to="/home" />
        </Switch>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default withWidth()(App);
