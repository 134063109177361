import { makeStyles } from "@material-ui/core/styles";
import ticket_border from "../../../Assets/svgs/border_timbre.svg";

export const useStyles = makeStyles((theme) => ({
  main: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& .event": {
      width: "80%",

      display: "flex",
      alignItems: "center",
      justifyContent: "center",

      [theme.breakpoints.down("md")]: {
        width: "95%",
        flexDirection: "column",
      },

      "& .img_container": {
        width: "35%",
        aspectRatio: "260/330",
        backgroundImage: `url(${ticket_border})`,
        backgroundPosition: "50% 50%",
        backgroundSize: "100% 100%",
        backgroundRepeat: "no-repeat",
        transform: "translateX(5%)",
        zIndex: "5",

        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        [theme.breakpoints.down("md")]: {
          width: "100%",
          transform: "translateX(0%) translateY(5%) ",
        },

        "& img": {
          height: "80%",
          width: "80%",
          objectFit: "cover",
          border: "solid 4px white",
        },
      },

      "& .data_container": {
        width: "60%",
        backgroundColor: "white",
        transform: "translateX(-5%)",
        borderRadius: "20px",
        padding: "20px 20px 20px 60px",
        textAlign: "right",
        boxShadow: "0px 3px 6px rgba(0,0,0,0.12)",
        position: "relative",

        [theme.breakpoints.down("md")]: {
          width: "100%",
          transform: "translateX(0%) translateY(-5%) ",
          padding: "20px 10px 10px 10px",
        },

        "& h2": {
          fontSize: "40px",
          fontFamily: "Hudhud",
          fontWeight: "300",
          margin: "0px",
          [theme.breakpoints.down("md")]: {
            fontSize: "35px",
          },
        },

        "& p": {
          fontSize: "22px",
          fontFamily: "Jazera",
          margin: "0px",
          [theme.breakpoints.down("md")]: {
            display: "none",
          },
        },

        "& .date , & .location": {
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          flexDirection: "row-reverse",
          margin: "10px 0px",

          "& .icon": {
            fontSize: "40px",
            margin: "0px 0px 0px 5px",
          },

          "& .value": {
            fontSize: "22px",
            fontFamily: "Jazera",
            margin: "0px",
          },
        },

        "& button": {
          position: "absolute",
          bottom: "10%",
          left: "10%",

          color: "white",
          height: "40px",
          padding: "0px 16px",
          fontSize: "18px",
          transition: "all 0.3s",
          fontFamily: "Abhaya",
          borderRadius: "10px",
          textTransform: "none",
          backgroundColor: "#FA6559",
          cursor: "pointer",
          border: "none",
          outline: "none",
          boxShadow: "0px 3px 6px rgba(0,0,0,0.16)",

          [theme.breakpoints.down("sm")]: {
            position: "relative",
            bottom: "auto",
            left: "auto",
            margin: "10px auto",
            display: "block",
            padding: "0px 30px",
            height: "45px",
            fontSize: "20px",
          },
        },
      },
    },
  },
}));
