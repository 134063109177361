import React, { useState, useEffect } from "react";
import { useStyles } from "./styles";
import Hidden from "@material-ui/core/Hidden";

import Swipper1 from "../../Components/Complex/Swipper1";
import Container1 from "../../Components/Simple/Container1";
import {
  GetLang,
  GetMember,
  SetMember,
  GetScreenSize,
} from "../../Actions/UserRedux";
import { useDispatch } from "react-redux";
import OutlinedButton from "../../Components/Simple/buttons/OutlinedButton";
import { SignInFn, logout } from "../../services/Auth";
import { FadeUp, FadeDown } from "../../Components/Animations/Fade";

import TypingText from "../../Components/Simple/TypingText";

import { MdEmail, MdPhoneInTalk } from "react-icons/md";
import { IoLocationSharp } from "react-icons/io5";
import { FaFacebookF } from "react-icons/fa";

let imagesUrls = [
  "https://res.cloudinary.com/nadi-palestine/image/upload/v1659214896/ManualDep/TopCarossel/pc/new2_cwkheu.jpg",
  "https://res.cloudinary.com/nadi-palestine/image/upload/v1659214786/ManualDep/TopCarossel/pc/new1_xulhrc.jpg",
  "https://res.cloudinary.com/nadi-palestine/image/upload/v1647379120/ManualDep/TopCarossel/pc/photo_2022-03-13_17-49-19_ebbc9a.jpg",
  "https://res.cloudinary.com/nadi-palestine/image/upload/v1647379120/ManualDep/TopCarossel/pc/photo_2022-03-13_17-49-12_uuszo4.jpg",
];

let imagesUrlsMobile = [
  "https://res.cloudinary.com/nadi-palestine/image/upload/v1650047658/ManualDep/TopCarossel/mobile/photo_2022-04-15_19-37-15_uxyndw.jpg",
  "https://res.cloudinary.com/nadi-palestine/image/upload/v1650047482/ManualDep/TopCarossel/mobile/photo_2022-04-15_19-45-25_jxcm7w.jpg",
  "https://res.cloudinary.com/nadi-palestine/image/upload/v1650047482/ManualDep/TopCarossel/mobile/photo_2022-04-15_19-37-14_vtc8qi.jpg",
];

const Head = () => {
  let lang = GetLang() === "EN";
  let size = GetScreenSize();
  const {
    S_container,
    S_img,
    S_vertical,
    S_content,
    S_ar_content,
    S_h1,
    S_h2,
  } = useStyles();

  const styles = useStyles();
  const cs = useStyles();
  const [elements, SetElements] = useState([]);
  const [images, setImages] = useState([...imagesUrls]);

  useEffect(() => {
    if (size === "xs") {
      setImages([...imagesUrlsMobile]);
    } else {
      setImages([...imagesUrls]);
    }
  }, [size]);

  useEffect(() => {
    let a = images.map((item, key) => {
      return (
        <div
          key={key}
          className={S_img}
          style={{ backgroundImage: `url(${item})` }}
        />
      );
    });
    SetElements([...a]);
  }, [images]);

  return (
    <div className={S_container}>
      <Swipper1 elements={elements} />

      <div style={{ marginTop: "-656px" }} className="body">
        <Hidden smDown>
          <Container1>
            <div className={S_vertical}>
              {!lang ? (
                <div>
                  <TypingText>
                    .مرحبا بكم في الموقع الرسمي لمنتدى فلسطين
                  </TypingText>
                </div>
              ) : null}

              {lang ? (
                <div>
                  <TypingText>
                    Welcome to the official website of PALESRINE FORUM.
                  </TypingText>
                </div>
              ) : null}
            </div>
          </Container1>

          <div className={S_content + ` ${S_ar_content}`}>
            <FadeDown>
              {lang ? (
                <h1 className={S_h1}>PALESTINE FORUM.</h1>
              ) : (
                <h1 className={S_h1 + " Hudhud"}>منتدى فلسطين</h1>
              )}
            </FadeDown>

            <FadeUp delay={1000}>
              {lang ? (
                <h2 className={S_h2}>
                  Saheb Ettabaa Association of Islamic Culture.
                </h2>
              ) : (
                <h2 className={S_h2 + " Hudhud"}>
                  جمعيّة صاحب الطابع للثقافة الإسلاميّة
                </h2>
              )}
            </FadeUp>
          </div>

          <div className={styles.timing}>
            <div className="time red">التوقيت</div>
            <div className="time grey">
              كل يوم إربعاء بداية من الساعة 7 مساء
            </div>
          </div>
        </Hidden>

        <Hidden mdUp>
          <div className={cs.white_card}>
            {lang ? (
              <div>
                <h1>PALESTINE FORUM.</h1>

                <h3>Welcome to the official website of PALESRINE FORUM.</h3>

                <h2>Saheb Ettabaa Association of Islamic Culture.</h2>
              </div>
            ) : (
              <div className="ar">
                <h1>منتدى فلسطين</h1>

                <h3>مرحبا بكم في الموقع الرسمي لمنتدى فلسطين</h3>

                <h2>جمعيّة صاحب الطابع للثقافة الإسلاميّة</h2>
              </div>
            )}
            <LogInLogOut />
          </div>
        </Hidden>
      </div>
    </div>
  );
};

const LogInLogOut = ({ lang }) => {
  const dispatch = useDispatch();
  let member = GetMember();

  const Login = async () => {
    dispatch(SetMember(await SignInFn()));
  };

  const Logout = async () => {
    dispatch(SetMember(await logout()));
  };

  return (
    <>
      {member ? (
        <OutlinedButton
          style={{ padding: "0px 40px", margin: "20px 0px 0px 0px" }}
          onClick={Logout}
        >
          {lang === "EN" ? "Sign out" : "تسجيل الخروج"}
        </OutlinedButton>
      ) : (
        <OutlinedButton
          style={{ padding: "0px 40px", margin: "20px 0px 0px 0px" }}
          onClick={Login}
        >
          {lang === "EN" ? "Sign up" : "الإشتراك"}
        </OutlinedButton>
      )}
    </>
  );
};

const MobileHeader = () => {
  const styles = useStyles();
  const [inHome, setinHome] = useState(true);

  return (
    <div className={!inHome ? styles.inhome : ""}>
      <div className={`${styles.mobile_v} `}>
        <div className="rxs">
          <div className="rx">
            <MdEmail className="icon" />
          </div>
          <div className="rx">
            <IoLocationSharp className="icon" />
          </div>
          <div className="rx">
            <FaFacebookF className="icon" />
          </div>
          <div className="rx">
            <MdPhoneInTalk className="icon" />
          </div>
        </div>

        <h2>منتدى فلسطين</h2>
        <h4>جمعية صاحب الطابع للثقافة الإسلامية</h4>

        <button>الإشتراك</button>
      </div>
    </div>
  );
};

const RealHeader = () => {
  return (
    <div>
      <Head />
      <MobileHeader />
    </div>
  );
};

export default RealHeader;
