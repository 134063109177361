import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    margin: "auto",
    position: "relative",

    [theme.breakpoints.down("xl")]: {
      maxWidth: "95%",
    },

    [theme.breakpoints.down("lg")]: {
      maxWidth: "95%",
    },

    [theme.breakpoints.down("md")]: {
      maxWidth: "95%",
    },

    [theme.breakpoints.down("sm")]: {
      maxWidth: "95%",
    },

    [theme.breakpoints.down("xs")]: {
      maxWidth: "95%",
      overflow: "hidden",
    },
  },
}));

const Container1 = (props) => {
  const { container } = useStyles();

  return <div className={container}>{props.children}</div>;
};

export default Container1;
