import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    display: "flex",
    alignItems: "stretch",
    justifyContent: "space-between",
    padding: "10px",
    backgroundColor: "white",
    boxShadow: "0px 3px 6px rgba(0,0,0,0.12)",
    borderRadius: "10px",
    cursor: "pointer",
    margin: "0px 10px 10px 10px",

    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },

    "& img": {
      height: "250px",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
        margin: "auto",
      },
    },

    "& .content": {
      flexGrow: "1",
      padding: "0px 8px",

      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",

      [theme.breakpoints.down("xs")]: {
        width: "100%",
      },

      "& h2": {
        fontSize: "26px",
        lineHeight: "1.3",
        fontFamily: "Hudhud",
        textAlign: "right",
        margin: "10px 0px 10px 0px",
        fontWeight: "400",
      },

      "& .description": {
        color: "#0F0E0C",
        fontWeight: "700",
        fontSize: "16px",
        width: "100%",
        padding: "0px",
        margin: "0px",
        textAlign: "center",
        fontFamily: "Jazera",
        textAlign: "justify",
        lineHeight: "2",
        direction: "rtl",

        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        "-webkit-line-clamp": "3",
        "-webkit-box-orient": "vertical",
      },

      "& .author": {
        fontFamily: "Hudhud",
        fontSize: "20px",
        margin: "10px 0px 0px 0px",
        color: "#13613A",
        textAlign: "right",
        [theme.breakpoints.down("xs")]: {
          fontSize: "24px",
        },
      },

      "& .categ_and_more": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",

        "& .categ": {
          color: "#01161E",
          backgroundColor: "#FB6459",
          padding: "0px 10px",
          fontSize: "22px",
          fontFamily: "Hudhud",
          borderRadius: "10px",

          [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
          },
        },

        "& .more": {
          color: "white",
          backgroundColor: "black",
          padding: "0px 10px",
          fontSize: "22px",
          fontFamily: "Hudhud",
          borderRadius: "10px",

          [theme.breakpoints.down("xs")]: {
            fontSize: "20px",
          },
        },
      },
    },
  },

  vertical: {
    flexDirection: "column",
    "& img": {
      width: "100%",
      height: "auto !important",
    },
    "& .content": {
      width: "100%",
    },
  },

  // main_ar: {
  //   textAlign: "right",
  //   direction: "ltr",
  //   fontFamily: "roxbor",
  //   "& .author": {
  //     justifyContent: "right",
  //   },
  //   "& .container_txt": {
  //     "& p": {
  //       fontSize: "28px",
  //       [theme.breakpoints.down("xs")]: {
  //         fontSize: "20px",
  //       },
  //     },
  //   },
  // },
  // main_en: {
  //   textAlign: "left",
  //   direction: "rtl",
  //   fontFamily: "Belleza",
  //   "& .author": {
  //     justifyContent: "left",
  //   },
  //   "& .container_txt": {
  //     "& p": {
  //       fontSize: "28px",
  //       [theme.breakpoints.down("xs")]: {
  //         fontSize: "20px",
  //       },
  //     },
  //   },
  // },
}));
