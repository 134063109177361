import React,{useRef,useEffect} from 'react'

function ScrollToThis() {

    const my_ref = useRef(null)
    /*const scrollTo = (ref) => {
        if (ref && ref.current) {
          ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
      }*/

    useEffect(()=>{
        if(my_ref){
            my_ref.current.scrollIntoView({behavior:'smooth'});
        }
    },[my_ref])

    return (
        <div style={{transform:"translateY(-65px)"}} ref={my_ref} >
            
        </div>
    )
}

export default ScrollToThis
