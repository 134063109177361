import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  main: {
    // backgroundColor: theme.palette.black.main,
    padding: "50px 40px 100px 40px",
    width: "100%",
    textAlign: "center",

    boxSizing: "border-box",
    fontFamily: "roxbor",
    color: "white",

    [theme.breakpoints.down("sm")]: {
      padding: "50px 10px 100px 10px",
    },

    "& .cards": {
      justifyContent: "center",
    },

    "& .ar": {
      "& h1": {
        fontFamily: "Hudhud",
        fontWeight: "500",
        color: "black",
      },
    },

    "& .en": {
      "& h1": {
        fontFamily: "roxbor",
        color: "black",
      },
    },

    "& h1": {
      display: "inline",
      color: "white",
      fontSize: "60px",
      borderBottom: "solid 5px black",
      padding: "10px 30px",
      margin: "0px",

      [theme.breakpoints.down("sm")]: {
        display: "block",
        fontSize: "35px",
        marginBottom: "30px",
      },
    },
  },
  card: {
    height: "100%",
    padding: "30px 0px 20px 0px",
    borderBottom: "solid 2px black",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "flex-start",

    cursor: "pointer",

    [theme.breakpoints.down("sm")]: {
      margin: "0px 40px",
    },

    "& .text": {
      "& h3": {
        fontFamily: "roxbor",
        fontSize: "26px",
        fontWeight: "300",
        textAlign: "center",
        margin: "20px 0px 0px 0px",
      },

      "& .ar_h3": {
        fontFamily: "Hudhud",
        textAlign: "center",
      },
    },
  },

  cards_for_selections: {
    height: "80vh",
    width: "100%",
    //backgroundColor: "red",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: "70px",

    [theme.breakpoints.down("xs")]: {
      height: "auto",
      marginTop: "0px",
    },

    "& .group_2": {
      width: "23%",
      height: "100%",

      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      flexDirection: "column",

      [theme.breakpoints.down("xs")]: {
        width: "48%",
      },

      "& .card": {
        // backgroundColor: "#181415",
        backgroundPosition: "50% 50%",
        backgroundSize: "cover",
        width: "100%",
        border: "solid 1px #a8b3cf33",
        borderRadius: "20px",
        padding: "20px",
        fontSize: "25px",
        color: "white",
        position: "relative",

        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        flexDirection: "column",

        transition: "all 0.2s",
        cursor: "pointer",
        "&:hover": {
          transform: "scale(1.05)",
        },

        "& .icon": {
          position: "absolute",
          top: "20px",
          right: "20px",
          color: "white",
          fontSize: "30px",
        },
      },

      "& .card_ar": {
        fontFamily: "Hudhud",
      },

      "& .h25": {
        height: "30%",
      },
      "& .h50": {
        height: "47%",
      },
      "& .h75": {
        height: "64%",
      },
      "& .h75, .h50, .h25": {
        [theme.breakpoints.down("xs")]: {
          height: "200px",
          margin: "20px 0px",
          "&:hover": {
            transform: "scale(1) !important",
          },
        },
      },
    },
  },
}));
