import React, { useEffect } from "react";

import { GetLang } from "../../../Actions/UserRedux";

import Swipper2 from "../../../Components/Complex/Swipper2";

import { FadeDown } from "../../../Components/Animations/Fade";
// import Spinner from "../../Components/Simple/Spinner";

import { useHistory } from "react-router";

import EventCard from "../../../Components/Cards/EventCard/EventCard";

import { useStyles } from "./styles";
import { NavLink } from "react-router-dom";

export default function OurEvents({ events }) {
  const css = useStyles();
  useEffect(() => {}, [events]);
  let lang = GetLang() === "EN";
  const history = useHistory();

  const gotopage = () => {
    history.push("/events");
  };

  return events.length > 0 ? (
    <div className={css.main}>
      <h2
        className={`${css.S_h2} ${css.S_h2_fixer} ${
          !lang ? ` ${css.ar_h2}` : ""
        }`}
      >
        <FadeDown delay={0} duration={1000}>
          <NavLink to="/events">
            {lang ? <>Our Events</> : <>الفعاليات</>}
          </NavLink>
        </FadeDown>
      </h2>
      <Swipper2
        gotopage={gotopage}
        Component={EventCard}
        elements={events.filter((eve) => {
          return (lang && eve.en) || (!lang && eve.ar);
        })}
      />
    </div>
  ) : null;
}
