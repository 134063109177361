import React from "react";
import styles from "./index.module.css";

function Edjes({ onMouseLeave }) {
  return (
    <container className={styles["main"]}>
      <span
        onMouseLeave={onMouseLeave}
        className={`${styles["edje"]} ${styles["edje_L"]}`}
      />
      <span
        onMouseLeave={onMouseLeave}
        className={`${styles["edje"]} ${styles["edje_T"]}`}
      />
      <span
        onMouseLeave={onMouseLeave}
        className={`${styles["edje"]} ${styles["edje_R"]}`}
      />
    </container>
  );
}

export default Edjes;
